import * as React from "react"
export const ToggleOn400Icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"

    fill="/Users/alexander/Downloads/chat_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.svg#5f6368"
    viewBox="0 -960 960 960"

  >
    <path d="M280-240q-100 0-170-70T40-480q0-100 70-170t170-70h400q100 0 170 70t70 170q0 100-70 170t-170 70H280Zm0-80h400q66 0 113-47t47-113q0-66-47-113t-113-47H280q-66 0-113 47t-47 113q0 66 47 113t113 47Zm400-40q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35ZM480-480Z" />
  </svg>
)

