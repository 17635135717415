
import React, { useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./authors.scss";

export interface AuthorsProps {
    imgSrc: string;
    name: string;
    description: string;
    bulletPoints: string[];
    myCourses?: string[]; 
}

export const AuthorsData: AuthorsProps[] = [
    {
        imgSrc: "alex_img",
        // imgSrc: "alex_img_desktop",
        name: "Aleksander Kotarski",
        description: "Ukończył z wyróżnieniem Informatykę stosowaną na Uniwersytecie Jagielońskim. Korepetytor matematyki i informatyki z wieloletnim doświadczeniem.",
        bulletPoints: [
            "Współzałożyciel serwisu Nasesje.com",
            "Autor kursów i artykułów specjalistycznych",
            "Autor książki \"Poręczny C++\""
        ],
        myCourses: [
            "Macierze i Wektory",
            "Granice ciągów i funkcji",
            "Teoria języków formalnych",
            "Liczby Binarne i Algebra Boole'a",
            "Całki nieoznaczone"
        ]
    },
    {
        imgSrc: "piotr_img",
        name: "Piotr Książek",
        description: "Absolwent Informatyki Stosowanej na Uniwersytecie Jagiellońskim, programista z czteroletnim doświadczeniem komercyjnym w językach C#, C++ oraz TypeScript. Pracował nad tworzeniem gier 3D oraz rozwojem oprogramowania Autodesk Civil3D, gdzie mógł wykorzystać w praktyce wiedzę z zakresu informatyki, algebry liniowej oraz analizy matematycznej.",
        bulletPoints: [
            "Współzałożyciel serwisu Nasesje.com",
            "Autor kursów i artykułów specjalistycznych",
        ],
        myCourses: [
            "Macierze i Wektory",
            "Liczby zespolone",
            "Teoria języków formalnych",
            "Liczby Binarne i Algebra Boole'a",
            "Całki nieoznaczone"
        ]
    },
    {
        imgSrc: "ola_img",
        name: "Aleksandra",
        description: "Studentka fizyki na Uniwersytecie Jagiellońskim i aspirująca pedagożka z kilkuletnim doświadczeniem korepetytorskim, autorka licznych materiałów dydaktycznych w różnych językach, popularyzatorka nauki.",
        bulletPoints: [
            "Twórczyni kursów dla platformy NaSesje.com",
            "Specjalistka w dziedzinie fizyki i matematyki",
        ],
        myCourses: [
            "Dynamika na studia"
        ]
    },
    {
        imgSrc: "staszek_img",
        name: "Stanisław Sender",
        description: "Ukończył studia licencjackie z Metod Ilościowych i Systemów Informacyjnych i jest w trakcie studiów magisterskich na kierunku Analiza Danych Big Data na SGH. Korepetytor z matematyki i statystyki z wieloletnim doświadczeniem. Zawodowo zajmuje się analizą danych.",
        bulletPoints: [
            "Ex-wiceprzewodniczący SKN Statystyki SGH",
            "Wykładowca w kolejnych edycjach Wielkiej Powtórki ze Statystyki"        
        ],
        myCourses: [
            "Statystyka na studia"
        ]
    }
]

export const Authors = (props: AuthorsProps) => {

    const { imgSrc, name, description, bulletPoints } = props;

    return (
        <div className="course-preview__main-section__left-panel__text-section__about-author__author">
            <div className="course-preview__main-section__left-panel__text-section__about-author__author__img-wrapper">
                <img alt="author" src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/authors/${imgSrc}.png`}/>
            </div>
            <div className="course-preview__main-section__left-panel__text-section__about-author__author__description">
                <h2>{name}</h2>
                <span className="course-preview__main-section__left-panel__text-section__about-author__author__description__text">
                    {description}
                </span>

                {
                    bulletPoints.map((el, index) => 
                        <div key={index} className="course-preview__main-section__left-panel__text-section__about-author__author__description__bullet-section">
                            <div className="course-preview__main-section__left-panel__text-section__about-author__author__description__bullet-section__bullet"/>
                            <span>
                                {el}
                            </span>
                        </div>   
                    )
                }
            </div>
        </div>
    )
}