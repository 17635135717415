import { Dispatch, SetStateAction } from 'react';
import { CloseIcon300 } from '../../Icons/CloseIcon300';

interface CheckoutCloseButtonProps {
    setBuyNowClicked: Dispatch<SetStateAction<boolean>>;
    isBuyAllCoursesUpsell: boolean;
}

export const CheckoutCloseButton = ({ setBuyNowClicked, isBuyAllCoursesUpsell }: CheckoutCloseButtonProps) => {
    const handleClose = () => {
        let html = document.querySelector('html');
        if (html !== null) html.classList.remove('html-not-scrollable');
        document.body.classList.remove('body-not-scrollable');
        setBuyNowClicked(false);
    };

    return (
        <div 
            className={`checkout__signup-box__title__mobile-close-button ` + (isBuyAllCoursesUpsell ? "buy-all-courses-upsell" : "")}
            onClick={handleClose}
        >
            <CloseIcon300/>
        </div>
    );
};