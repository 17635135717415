import { useContext, useRef, useEffect, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../../components/auth/authContext/AuthContextProvider";
import { Guarantee } from "../../../../MainPage/Guarantee/Guarantee";
import { Bullet } from "../../CourseSectionsWrapper/DisplayedCourseDescription/DisplayedCourseDescription";
import { Block } from "shared/block";
import { BundleContent } from "../BundleSectionsWrapper";
import { DisplayedDescriptionWrapper } from "../../DisplayedDescriptionWrapper/DisplayedDescriptionWrapper";
import { YouAreNotAlone } from "../../YouAreNotAlone/YouAreNotAlone";
import React from "react";
import { BundleTableOfContents } from "./BundleTableOfContents/BundleTableOfContents";

interface DisplayedBundleDescriptionProps {
    description: Block[];
    bundleContent: BundleContent[];
    bundlePriceAfterDiscount: number;
    setTableOfContentsRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
    setAboutAuthorsRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
    fromLanding?: boolean;
    courseName: string;
    handleBuyNowClick: () => void;
}

export const DisplayedBundleDescription = (props: DisplayedBundleDescriptionProps) => {
    const { t } = useTranslation();
    
    const authContext = useContext(AuthContext);

    const getAvgPrice = () => {
        return Number((props.bundlePriceAfterDiscount / props.bundleContent.length).toFixed(2));
    }

    return (
        <DisplayedDescriptionWrapper
            courseName={props.courseName}
            setTableOfContentsRef={props.setTableOfContentsRef}
            setAboutAuthorsRef={props.setAboutAuthorsRef}
            fromLanding={props.fromLanding}
            isAllCoursesBundle={true}
            handleBuyNowClick={props.handleBuyNowClick}>
                
            <div className="course-preview__main-section__left-panel__text-section__learning-method__text">
                <div>  
                    Paczka <b>Wszystkie Kursy </b> zawiera {props.bundleContent.length} kursów.
                </div>
                <div>
                    Znajdziesz tam wszystko, czego potrzebujesz do <b className="purple">zdania kolokwiów i sesji.</b>
                </div>
                    <br/>    
                <div>
                    Prowadzimy Cię za rękę przez najważniejsze zagadnienia, w przyjemnej atmosferze i w tempie, które wybierasz Ty.
                </div>
                    <br/><br/>
                <h2>
                    Każda z{" " + props.bundleContent.reduce((sum, course) => 
                            sum + (course.chapters?.reduce((chapterSum, chapter) => 
                                chapterSum + chapter.lessons.length, 0) || 0)
                        , 0)} lekcji w paczce składa się z:
                </h2>
                <br/>
                    <Bullet emoji="🎥">
                        <span><b className="purple">filmów</b> z wieloma animacjami, tłumaczącego teorię oraz zadania</span>
                    </Bullet>
                    <Bullet emoji="📝">
                        <span><b className="purple">zadań otwartych</b> wraz z omówieniami</span>
                    </Bullet>
                    <Bullet emoji="🧠">
                        <span><b className="purple">zadań zamkniętych</b> w formie quizów</span>
                    </Bullet>
                    <Bullet emoji="📒">
                        <span><b className="purple">notatek</b> z ilustracjami</span>
                    </Bullet>
                <br/>

                <span>
                    Każdy kurs kończy się sporym <b>interaktywnym testem końcowym</b> kompleksowo sprawdzającym Twoją wiedzę.
                </span>

                <div className="course-preview__main-section__left-panel__text-section__learning-method__laptop">
                    <img  className="laptop" alt="" src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/laptops/laptop-test-koncowy.jpg`}></img>
                </div>

                <h2>
                    Zakup kursów nie wiąże się z żadnym ryzykiem
                </h2>
                
                <Guarantee authContext={authContext} component="course preview"/>

                <h2>
                    Dlaczego warto kupić paczkę?
                </h2>

                <div className="course-preview__main-section__left-panel__text-section__learning-method__text">
                    <Bullet emoji="💰" card={true}>
                        <div className="course-preview__main-section__left-panel__text-section__learning-method__text__bullet-point__emoji--container--bundle">
                            <span>Zakup paczki jest o wiele <b>tańszy</b> niż kupowanie osobno.</span>
                            <span>Cena pojedynczego kursu w pakiecie to <b>{getAvgPrice()} zł</b>.</span>
                        </div>
                    </Bullet>
                    <Bullet emoji="👨‍🎓" card={true}>
                        <div className="course-preview__main-section__left-panel__text-section__learning-method__text__bullet-point__emoji--container--bundle">
                            <span>W paczce dostajesz wszystko, czego potrzebujesz do spokojnego <b>przeżycia studiów</b>.</span>
                        </div>
                    </Bullet>
                    <Bullet emoji="🔥" card={true}>
                        <div className="course-preview__main-section__left-panel__text-section__learning-method__text__bullet-point__emoji--container--bundle">
                            <span>Najlepsza inwestycja w Twoje studia.</span>
                            <span>Komplet akademickich kursów <b>w cenie dwóch godzin korepetycji</b>.</span>
                        </div>
                    </Bullet>
                    <Bullet emoji="📉" card={true}>
                        <div className="course-preview__main-section__left-panel__text-section__learning-method__text__bullet-point__emoji--container--bundle">
                            <span>Mniej stresu, więcej pewności siebie.</span>
                            <span>Sukces na studiach, to zdanie wielu przedmiotów a Ty <b>nie dasz się zaskoczyć</b>.</span>
                        </div>
                    </Bullet>
                </div>

                <YouAreNotAlone />

                <br/>
                <h2>
                    W paczce otrzymasz aż {props.bundleContent.length} kursów!
                </h2>
                <BundleTableOfContents 
                    bundleContent={props.bundleContent}
                    setTableOfContentsRef={props.setTableOfContentsRef}
                    fromLanding={props.fromLanding}
                />
            </div>
        </DisplayedDescriptionWrapper>
    )
}