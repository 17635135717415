import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { StripeElementLocale, StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { checkNumberOfCharacters, checkCapitalization, checkNonCapitlization, checkDigits, getInputFieldState } from "../../components/auth/shared/sharedFunctions";
import { PasswordLabel } from "../../components/auth/signUp/passwordLabel/PasswordLabel";
import { Alert } from "../../components/auth/shared/sharedInterfaces";
import { CheckBox } from "../../components/auth/signUp/CheckBox/CheckBox";
import { CheckoutFallback } from "./CheckoutFallback/CheckoutFallback";

import { StripePaymentPanel } from "./StripePaymentPanel.tsx/StripePaymentPanel";

import { stripePromise, options } from "../../paymentComponents/paymentCompontents";

import { OrderType, PaymentIntent } from "../../../../shared/paymentIntent";

import "./checkout.scss";

import i18n from "../../i18n";
import { post } from "../../utilities/rest/apiClient";
import { Unauthorized } from "../../utilities/rest/errors";
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";
import { CreditCardIcon400 } from "../Icons/CreditCardIcon400";
import { CloseIcon300 } from "../Icons/CloseIcon300";
import { WarningFilledIcon } from "../Icons/WarningFilledIcon";
import { CheckIcon400 } from "../Icons/CheckIcon400";
import { useLocation } from "react-router-dom";
import { handleStatsRegister } from "../../utilities/pageStatsLogging";
import { Blik } from "./PaymentLogos/Blik";
import { Visa } from "./PaymentLogos/Visa";
import { Mastercard } from "./PaymentLogos/Mastercard";
import { Przelewy24 } from "./PaymentLogos/Przelewy24";
import { InfoIcon400 } from "../Icons/InfoIcon400";
import MediaQuery from "react-responsive";
import { ApplePay } from "./PaymentLogos/ApplePay";
import { BuyAllCoursesUpsell } from "./buyAllCoursesUpsell/BuyAllCoursesUpsell";
import { CheckoutCloseButton } from "./checkoutCloseButton/CheckoutCloseButton";
import { BuyAllCoursesData } from "shared/coursesPreviewDto";
import { getPriceAfter } from "../../utilities/pricing/discount";

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;


interface CustomPaymentIntent extends PaymentIntent {
    customPaymentOrder?: string[];
}

interface CheckoutProps {
    setBuyNowClicked: Dispatch<SetStateAction<boolean>>;
    courseID: string | undefined,
    basicOptionSelected: boolean,
    enchancedOptionSelected: boolean,
    discountedPrice: number,
    buyAllCoursesData: BuyAllCoursesData,
    courseIcon: string,
    originalPrice: number,
    courseName: string,
    isAllCoursesBundleDirectlyFromShop: boolean // if true, then we are buying all courses bundle directly from shop, not upsell.
}

declare global {
    interface Window {
      gtag: (...args: any[]) => void;
      fbq: (...args: any[]) => void;
    }
  }
  

const safariInUse = () => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return isSafari;
};

export const Checkout = (props: CheckoutProps) => {
    const { t } = useTranslation();

    const simplifiedCheckout = (process.env.REACT_APP_PAYMENT_METHOD === "simplified");
    const appleDevice = safariInUse();
    const [selectedPaymentForm, setSelectedPaymentForm] = useState('blik');


    const location = useLocation();

    const authContext = useContext(AuthContext);
    
    const [alreadyHaveAccount, setAlreadyHaveAccount] = useState(false);
    
    const [displayFallback, setDisplayFallback] = useState(true);

    const [email, setEmail] = useState("");
    const [validEmail, setValidEmail] = useState(false);
    const [emailSelected, setEmailSelected] = useState(false);

    const [password, setPassword] = useState("");
    const [validPassword, setValidPassword] = useState(false);
    const [passwordSelected, setPasswordSelected] = useState(false);

    const [confirmPassword, setConfirmPassword] = useState('');
    const [validPasswordConfirmation, setValidPasswordConfirmation] = useState(false);
    const [passwordConfirmSelected, setPasswordConfirmSelected] = useState(false);

    const [eightSymbolsChecked, setEightSymbolsChecked] = useState(false);
    const [bigLetterChecked, setBigLetterChecked] = useState(false);
    const [digitChecked, setDigitChecked] = useState(false);
    const [smallLetterChecked, setSmallLetterChecked] = useState(false);

    const [formChecked, setFormChecked] = useState(false);

    const [signupError, setSignupError] = useState(false);
    const [signupErrorMessage, setSignupErrorMessage] = useState("");

    const [clientSecret, setClientSecret] = useState("");

    const [waitingForSecret, setWaitingForSecret] = useState(false);

    const [closeUpsell, setCloseUpsell] = useState(false);

    const [buyAllCourses, setBuyAllCourses] = useState(true);

    const emailAlert = t("checkout.incorrectEmailAddress");

    const [clickedNextButton, setClickedNextButton] = useState(false);

    const updatedOptions: StripeElementsOptions = {
        ...options, 
        clientSecret: clientSecret,
        mode: undefined
    };

    useEffect(() => {
        if(props.isAllCoursesBundleDirectlyFromShop) {
            setCloseUpsell(true);
        }

        if(authContext.loggedIn) {
            //jeśli to upsell, to niec nie rób do momentu przejścia dalej na upsellu
            if((!props.isAllCoursesBundleDirectlyFromShop) && (!clickedNextButton)){
                return;
            }

            sendPaymentIntent(true);
        }
        else {
            setDisplayFallback(false);
        }
        
    }, [clickedNextButton]);

    const passwordAlerts: Alert[] = [
        { text: t("signUp.passwordRequirements.min8signs"), status: eightSymbolsChecked },
        { text: t("signUp.passwordRequirements.min1capitalLetter"), status: bigLetterChecked },
        { text: t("signUp.passwordRequirements.min1nonCapitalLetter"), status: smallLetterChecked },
        { text: t("signUp.passwordRequirements.min1digit"), status: digitChecked },
    ];


    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        const new_email = e.target.value;
        
        setEmail(new_email);
  
        setValidEmail(emailRegex.test(new_email) && (new_email.length !== 0));
    };

    const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const new_password = e.target.value;
        setPassword(new_password);
  
        const digitsCheck = checkDigits(new_password, 1);
        const capitalizationCheck = checkCapitalization(new_password, 1);
        const numberOfCharactersCheck = checkNumberOfCharacters(new_password, 8);
        const nonCapitalizationCheck = checkNonCapitlization(new_password, 1);
  
        setEightSymbolsChecked(numberOfCharactersCheck);
        setBigLetterChecked(capitalizationCheck);
        setDigitChecked(digitsCheck);
        setSmallLetterChecked(nonCapitalizationCheck);
  
        if((confirmPassword === new_password) && (new_password.length !== 0)) setValidPasswordConfirmation(true)
        else setValidPasswordConfirmation(false);
  
        if(digitsCheck && capitalizationCheck && numberOfCharactersCheck && nonCapitalizationCheck) 
          setValidPassword(true);
        else
          setValidPassword(false);
    };

    const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        const new_passwordConfirmation = e.target.value; 
        
        setConfirmPassword(new_passwordConfirmation);

        if((new_passwordConfirmation === password) && (password.length !== 0)) setValidPasswordConfirmation(true)
        else setValidPasswordConfirmation(false);
    }

    const checkIfMaskActive = () => {
        const displayForPassword = !(validPassword || password.length === 0 ) && passwordSelected;
        const displayForPasswordConfirmation = !(validPasswordConfirmation || confirmPassword.length === 0) && passwordConfirmSelected;
        const displayForEmail = !(validEmail || email.length === 0 ) && emailSelected;


        return displayForPassword || displayForPasswordConfirmation || displayForEmail || signupError;
    }

    const enabledFinalButton = () => {

        if(!alreadyHaveAccount) {
            if(simplifiedCheckout) {
                return validEmail;
            }
            else {
                return (validEmail && validPassword && validPasswordConfirmation && formChecked);
            }
        } else {
            return (validEmail && validPassword);
        }
    }

    const gtagReportConversion = () => {

        // Call the gtag event for conversion
        window.gtag('event', 'conversion', {
          'send_to': 'AW-16740954580/WbIQCMze3-MZENTj2q4-',
          'transaction_id': ''
        });
        
        return;
      };


    const metaPixelReportConversion = () => {
        window.fbq('track', 'Purchase', { currency: 'PLN', value: 70.00 });
    };
    
    

    const handleFinalButtonClick = async () => {

        if(!enabledFinalButton()) {
            return;
        } 

        handleStatsRegister(
            location.pathname,
            false,
            false,
            false,
            true,
            false,
        );

        setWaitingForSecret(true);

        if(!alreadyHaveAccount) {

            if(simplifiedCheckout) {
                const data = { email: email }

                post<any, any>(`users/simplifiedSignup/${i18n.language}`, data)
                .then(() => {
                    gtagReportConversion();
                    metaPixelReportConversion();
                    sendPaymentIntent();
                    authContext.setLoggedIn(true);

                })
                .catch((error) => {

                    handleStatsRegister(
                        location.pathname,
                        false,
                        false,
                        false,
                        false,
                        false,
                        'buy - signup error'
                    );

                    setEmail("");
                    setValidEmail(false);
                    setSignupError(true);

                    setWaitingForSecret(false);
        
                    if(error instanceof Unauthorized){
                        const message = t("signUp.error.accountAlreadyExists");
                        setSignupErrorMessage(message);
                    } else {
                        const message = t("signUp.error.internalError");
                        setSignupErrorMessage(message);
        
                        setPassword("");
                        setValidPassword(false);
        
                        setConfirmPassword("");
                        setValidPasswordConfirmation(false);
        
                        setFormChecked(false);
                    }
                });

            } else {

                const data = { email: email, password: password, passwordConfirm: confirmPassword};

                post<any, any>(`users/quickSignup/${i18n.language}`, data)
                .then(() => {
                    gtagReportConversion();
                    metaPixelReportConversion();
                    sendPaymentIntent();
                })
                .catch((error) => {

                    handleStatsRegister(
                        location.pathname,
                        false,
                        false,
                        false,
                        false,
                        false,
                        'buy - signup error'
                    );

                    setEmail("");
                    setValidEmail(false);
                    setSignupError(true);

                    setWaitingForSecret(false);
        
                    if(error instanceof Unauthorized){
                        const message = t("signUp.error.accountAlreadyExists");
                        setSignupErrorMessage(message);
                    } else {
                        const message = t("signUp.error.internalError");
                        setSignupErrorMessage(message);
        
                        setPassword("");
                        setValidPassword(false);
        
                        setConfirmPassword("");
                        setValidPasswordConfirmation(false);
        
                        setFormChecked(false);
                    }
                });
            
            }
        }
        else {
            const data = { email: email, password: password };

            post<any, any>("users/login", data)
            .then(() => {
                gtagReportConversion();
                sendPaymentIntent();
            })
            .catch((error) => {

                handleStatsRegister(
                    location.pathname,
                    false,
                    false,
                    false,
                    false,
                    false,
                    'buy - login error'
                );

                setEmail("");
                setValidEmail(false);

                setPassword("");
                setValidPassword(false);

                setSignupError(true);

                setWaitingForSecret(false);
    
                if(error instanceof Unauthorized){
                    setSignupErrorMessage(`${t("checkout.invalidEmailOrPassword")}`);
                }
            });
        }

    }

    const sendPaymentIntent = (loggedIn?: boolean) => {
    
        const orderID = buyAllCourses ? props.buyAllCoursesData.coursePack.coursePackId : props.courseID as string;

        const orderId = [orderID];
        let orderType: OrderType[] = buyAllCourses ? ['allCoursesBundle'] : ['course'];

        if(props.enchancedOptionSelected && !buyAllCourses) { //TODO przetestować kupowanie z dodatkami
            orderType = ['courseAndExplanation']
        }


        let data: CustomPaymentIntent = {
            orderId,
            orderType,
        };


        if(simplifiedCheckout && (!alreadyHaveAccount) && (!loggedIn)) {

            let passedValue = selectedPaymentForm; 

            if(selectedPaymentForm === 'apple-pay')
                passedValue = 'card';

            const customPaymentOrder = [
                passedValue,
            ]
            
            data = {
                ...data,
                customPaymentOrder
            }
        }
            
        post<any, PaymentIntent>("payment/create-payment-intent", data)
        .then((returned_data) => {
            setClientSecret(returned_data.clientSecret);
        })
        .catch((error) => {
            setSignupError(true);

            handleStatsRegister(
                location.pathname,
                false,
                false,
                false,
                false,
                false,
                error.toString()
            );

            if(authContext.loggedIn) {
                setSignupErrorMessage(`${t("checkout.stripeSection.errors.unexpectedError")}`);
            }
            else if(!alreadyHaveAccount) {
                setSignupErrorMessage(`${t("checkout.accountCreatedServerErrorOccured")}`);
            } else {
                setSignupErrorMessage(`${t("checkout.loggedInServerErrorOccured")}`);
            }

            setEmail("");
            setValidEmail(false);

            setPassword("");
            setValidPassword(false);
            
            setConfirmPassword("");
            setValidPasswordConfirmation(false);
        });
    }

    const handleCheckoutClass = () => {
        let base = "checkout"

        let sufix = ""
        if(clientSecret || displayFallback) {
            sufix = "format-1"
        } else {
            if((!alreadyHaveAccount) && appleDevice ) {
                sufix = "format-3"
            }
            else {
                sufix = "format-2"
            }
        }

        if(!closeUpsell) {
            sufix = "upsell"
        }

        return `${base} ${base}--${sufix}`;
    }

    const getPriceForOrder = (buyAllCourses: boolean): number => {
        if(buyAllCourses) {
            return getPriceAfter(props.buyAllCoursesData.coursePack.discount, props.buyAllCoursesData.coursePack.price);
        }
        return props.discountedPrice;
    }

    const getOrderType = (buyAllCourses: boolean): OrderType => {
        if(buyAllCourses) {
            return 'allCoursesBundle';
        }
        return 'course';
    }

    const getOrderId = (buyAllCourses: boolean): string => {
        if(buyAllCourses) {
            return props.buyAllCoursesData.coursePack.coursePackId;
        }
        return props.courseID as string;
    }

    return (
        <div className="checkout-wrapper not-selectable">
            <div 
                className={handleCheckoutClass()}
            >
            {!closeUpsell ? <BuyAllCoursesUpsell 
                isLoggedIn={authContext.loggedIn} 
                setCloseUpsell={setCloseUpsell} 
                setBuyNowClicked={props.setBuyNowClicked} 
                buyAllCoursesData={props.buyAllCoursesData} 
                courseIcon={props.courseIcon} 
                coursePrice={props.discountedPrice} 
                courseName={props.courseName} 
                buyAllCourses={buyAllCourses}
                setBuyAllCourses={setBuyAllCourses}
                clickedNextButton={clickedNextButton}
                setClickedNextButton={setClickedNextButton}
                /> : 
                <>
                    {
                        signupError ? (
                            <div className="checkout__popup">
                                <div className="checkout__popup__headline">
                                <span>{t("checkout.warning")}</span>
                            </div>
                            <div className="checkout__popup__message">
                                <span>
                                    {signupErrorMessage}
                                </span>
                            </div>
                            <div 
                                onClick={() => {
                                    setSignupError(false);
                                    if(authContext.loggedIn) {
                                        let html = document.querySelector('html');
                                        if(html !== null) html.classList.remove('html-not-scrollable');
                                        document.body.classList.remove('body-not-scrollable');
                
                                        props.setBuyNowClicked(false);
                                    }

                                }} 
                                className="checkout__popup__close-button">
                                <span>{t("checkout.understood")}</span>
                            </div>
                        </div>
                    ) : ""
                }

                { 
                    checkIfMaskActive() && <div className={`checkout__mask ${authContext.loggedIn ? "checkout__mask--fallback-height" : ""} checkout__mask--${simplifiedCheckout ? "simplified" : "normal"}`}/>
                }

                {
                    (clientSecret) ? (
                        <Elements options={updatedOptions} stripe={stripePromise}>
                            <StripePaymentPanel email={email} price={getPriceForOrder(buyAllCourses)} setBuyNowClicked={props.setBuyNowClicked} purchaseType={getOrderType(buyAllCourses)} courseID={getOrderId(buyAllCourses)}/>
                        </Elements>
                    ) : displayFallback ? <CheckoutFallback/> : (
                        

                        <div className="checkout__signup-box">

                            <div className="checkout__signup-box__title">
                                <div>
                                    <CreditCardIcon400/>
                                    <span>{`${t("checkout.payment")}${simplifiedCheckout ? '' : ': 1/2'}`}</span>
                                </div>
                                <CheckoutCloseButton setBuyNowClicked={props.setBuyNowClicked} isBuyAllCoursesUpsell={!props.isAllCoursesBundleDirectlyFromShop}/>
                            </div>
        
                            <div className="checkout__signup-box__signup-login-buttons">
                                <div 
                                    className={`not-selectable checkout__signup-box__signup-login-buttons__button checkout__signup-box__signup-login-buttons__button--${!alreadyHaveAccount ? "active" : "inactive"}`}
                                    onClick={() => {if(alreadyHaveAccount) setAlreadyHaveAccount(false)}}
                                >
                                    <div>
                                        <span className="checkout__signup-box__signup-login-buttons__button__text--desktop">
                                            {t("checkout.IdontHaveAccountLong")}
                                        </span>
                                        <span className="checkout__signup-box__signup-login-buttons__button__text--mobile">
                                            {t("checkout.IdontHaveAccountShort")}
                                        </span>
                                    </div>
                                </div>
                                <div 
                                    className={`not-selectable checkout__signup-box__signup-login-buttons__button checkout__signup-box__signup-login-buttons__button--${alreadyHaveAccount? "active" : "inactive"}`}
                                    onClick={() => {if(!alreadyHaveAccount) setAlreadyHaveAccount(true)}}
                                >
                                    <div>
                                        <span>
                                            {t("checkout.IAlreadyHaveAccount")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            
                            <div className={`checkout__signup-box__login-ending checkout__signup-box__login-ending--${alreadyHaveAccount ? "displayed" : "hidden"}`}>

                                <div className="checkout__signup-box__login-ending__login-text">
                                    <div className="checkout__signup-box__login-ending__login-text__headline">
                                        <span>
                                            {t("checkout.welcomeBack")}
                                        </span>
                                    </div>
                                    <div className="checkout__signup-box__login-ending__login-text__main-text">
                                        <span>
                                            {t("checkout.logInToYourAccount")}
                                        </span>
                                    </div>
                                </div>

                                <div className={`checkout__signup-box__email-box${(emailSelected && checkIfMaskActive())? " visible" : ""}`}>
                                    <span>
                                        {t("checkout.emailAddress")}
                                    </span>
                                    <input
                                        className={
                                            (email.length !== 0) ? (validEmail ? "valid" : "invalid") : "" 
                                        }
                                        onFocus={() => setEmailSelected(true)}
                                        onBlur={() => setEmailSelected(false)}
                                        type="text"
                                        id="username"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />

                                    <div className={`checkout__signup-box__errors checkout__signup-box__errors--${((validEmail || email.length === 0) || !emailSelected ) ? "hide" : "display"}`}>
                                        <div className="checkout__signup-box__errors__triangle"/>
                                        <PasswordLabel text={emailAlert} status={ validEmail || (email.length === 0)} />
                                    </div>

                                    { 
                                        validEmail ? 
                                        <span className="valid valid--valid"><CheckIcon400/></span> : ""
                                    }    
                                    { 
                                        ((!validEmail) && (email.length !== 0)) ? 
                                        <span className="warning warning--invalid"><WarningFilledIcon/></span>: ""}
                                </div>
                                
                                {
                                    (simplifiedCheckout && (!alreadyHaveAccount)) ? (                                    
                                        <div className="checkout__signup-box__simplified-box">
                                            <MediaQuery minWidth={951}>
                                                <span className="checkout__signup-box__simplified-box__description">
                                                    <InfoIcon400/> Do tego adresu email zostanie przypisany twój kurs.
                                                </span>
                                                <span className="checkout__signup-box__simplified-box__description">
                                                    <InfoIcon400/> Hasło otrzymasz na podanego maila.
                                                </span>
                                            </MediaQuery>

                                            <MediaQuery maxWidth={950}>
                                                <span className="checkout__signup-box__simplified-box__description">
                                                    <div><InfoIcon400/></div> <div>Twój kurs zostanie przypisany do podanego adresu. Hasło wyślemy na twojego maila.</div>
                                                </span>
                                            </MediaQuery>

                                            
                                            <div className="checkout__signup-box__simplified-box__payment-selection">
                                                <label className="checkout__signup-box__simplified-box__payment-selection__label">
                                                    Wybierz formę płatności
                                                </label>
                                                <div className="checkout__signup-box__simplified-box__payment-selection__box">

                                                    <div className="checkout__signup-box__simplified-box__payment-selection__box__option">
                                                        <input
                                                            type="radio"
                                                            value="blik"
                                                            checked={selectedPaymentForm === 'blik'}
                                                            onChange={(e) => setSelectedPaymentForm(e.target.value)}
                                                        />
                                                        <label onClick={() => setSelectedPaymentForm('blik')}>
                                                            <span className="checkout__signup-box__simplified-box__payment-selection__box__option__checkout">Płatność BLIKIEM</span>
                                                            <Blik/>
                                                        </label>
                                                    </div>
                                                    {
                                                        appleDevice ?                                                 
                                                            <div className="checkout__signup-box__simplified-box__payment-selection__box__option">
                                                                <input
                                                                    type="radio"
                                                                    value="apple-pay"
                                                                    checked={selectedPaymentForm === 'apple-pay'}
                                                                    onChange={(e) => setSelectedPaymentForm(e.target.value)}
                                                                />
                                                                <label onClick={() => setSelectedPaymentForm('apple-pay')}>
                                                                    <span className="checkout__signup-box__simplified-box__payment-selection__box__option__checkout apple-pay-span" >Płatność Apple Pay</span>
                                                                    <ApplePay/>
                                                                </label>
                                                            </div> : <></>
                                                    }
                                                    <div className="checkout__signup-box__simplified-box__payment-selection__box__option">
                                                        <input
                                                            type="radio"
                                                            value="card"
                                                            checked={selectedPaymentForm === 'card'}
                                                            onChange={(e) => setSelectedPaymentForm(e.target.value)}
                                                        />
                                                        <label onClick={() => setSelectedPaymentForm('card')}>
                                                            <span className="checkout__signup-box__simplified-box__payment-selection__box__option__checkout">Płatność kartą</span>
                                                            <Visa/><Mastercard/>

                                                        </label>
                                                    </div>
                                                    <div className="checkout__signup-box__simplified-box__payment-selection__box__option">
                                                        <input
                                                            type="radio"
                                                            value="p24"
                                                            checked={selectedPaymentForm === 'p24'}
                                                            onChange={(e) => setSelectedPaymentForm(e.target.value)}
                                                        />
                                                        <label onClick={() => setSelectedPaymentForm('p24')}>
                                                            <span className="checkout__signup-box__simplified-box__payment-selection__box__option__checkout">Płatność Przelewy 24</span>
                                                            <Przelewy24/>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <span className="checkout__signup-box__simplified-box__label">
                                                Przechodząc dalej akceptujesz <b>regulamin</b> or <b>politykę prywatności</b> serwisu NaSesje.com.
                                            </span>
                                        </div>

                                    ) : (
                                        <div className={`checkout__signup-box__password-box checkout__signup-box__password-box--first${(passwordSelected && checkIfMaskActive())? " visible" : ""}`}>
                                            <span>
                                                {t("checkout.password")}
                                            </span>
                                            <input
                                                className={
                                                    (password.length !== 0) ? (validPassword ? "valid" : "invalid") : ""
                                                }
                                                onFocus={() => setPasswordSelected(true)}
                                                onBlur={() => setPasswordSelected(false)}
                                                type="password"
                                                maxLength={15}
                                                id="password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                            />
                                            <div className={`checkout__signup-box__errors checkout__signup-box__errors--${((validPassword || password.length === 0) || !passwordSelected ) ? "hide" : "display"}`}>
                                                <div className="checkout__signup-box__errors__triangle"/>
                                                {
                                                    passwordAlerts.map((el, index) => 
                                                        <PasswordLabel 
                                                            key={index} 
                                                            text={el.text} 
                                                            status={ el.status || (password.length === 0)} 
                                                        />
                                                    )
                                                }
                                            </div>
            
                                            { validPassword ? 
                                                <span className="valid valid--valid"><CheckIcon400/></span> : ""
                                            }    
                                            { ((!validPassword) && (password.length !== 0)) ? 
                                                <span className="warning warning--invalid"><WarningFilledIcon/></span> : ""}
                                        </div>
                                    )
                                }



                            </div>
                            
                            
                            <div className={`checkout__signup-box__signup-ending checkout__signup-box__signup-ending--${!alreadyHaveAccount ? "displayed" : "hidden"}`}>
                                
                            {
                                simplifiedCheckout ? (
                                    <></>
                                    
                                ) : (
                                    <>
                                    <div className={`checkout__signup-box__password-box checkout__signup-box__password-box--second${(passwordConfirmSelected && checkIfMaskActive())? " visible" : ""}`}>
                                        <span>
                                            {t("checkout.confirmPassword")}
                                        </span>
                                        <input
                                            className={
                                                (confirmPassword.length !== 0) ? (validPasswordConfirmation ? "valid" : "invalid") : ""
                                            }
                                            onFocus={() => setPasswordConfirmSelected(true)}
                                            onBlur={() => setPasswordConfirmSelected(false)}
                                            type="password"
                                            maxLength={15}
                                            id="password-confirm"
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                        />

                                        <div className={`checkout__signup-box__errors checkout__signup-box__errors--${((validPasswordConfirmation || confirmPassword.length === 0) || !passwordConfirmSelected ) ? "hide" : "display"}`}>
                                            <div className="checkout__signup-box__errors__triangle"/>
                                            <PasswordLabel text={t("signUp.passwordsNotTheSame")} status={ validPasswordConfirmation || (confirmPassword.length === 0)} />
                                        </div>

                                        { 
                                            validPasswordConfirmation ? 
                                            <span className="valid valid--valid"><CheckIcon400/></span> : ""}    
                                        { 
                                            ((!validPasswordConfirmation) && (confirmPassword.length !== 0)) ? 
                                            <span className="warning warning--invalid"><WarningFilledIcon/></span>: ""
                                        }
                                    </div>

                                    <CheckBox
                                        formChecked={formChecked}
                                        setFormChecked={setFormChecked}

                                        validPassword={validPassword}
                                        validEmail={validEmail}
                                        validPasswordConfirmation={validPasswordConfirmation}
                                    />
                                    </>
                                )
                            }
                                

                            </div>

                            {
                                (!waitingForSecret) ? 
                                <div className={`checkout__signup-box__finalize-transaction checkout__signup-box__finalize-transaction--${((!alreadyHaveAccount) && appleDevice ) ? 'format-3' : 'format-2'}`}>
                                    <div 
                                        className={`checkout__signup-box__finalize-transaction__button checkout__signup-box__finalize-transaction__button--${enabledFinalButton() ? "active" : "inactive"}`}
                                        onClick={handleFinalButtonClick}
                                    >
                                        <span>
                                            {t("checkout.goToTheLastStep")}
                                        </span>
                                    </div>
                                </div> 
                                : 
                                <div className={`checkout__signup-box__finalize-transaction__loading checkout__signup-box__finalize-transaction__loading--${((!alreadyHaveAccount) && appleDevice ) ? 'format-3' : 'format-2'}`}>
                                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                </div>
                            }

                        </div>
                    )
                }
                </>
            }
            </div>
        </div>
    );
}