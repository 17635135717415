import { useState } from "react";
import { CheckIcon400 } from "../../Icons/CheckIcon400";
import "./newDesktopIntroduction.scss";
import { ArrowForwardIos400 } from "../../Icons/ArrowForwardIos400";
import { InfoIcon400 } from "../../Icons/InfoIcon400";
import { GuaranteePopup } from "../Guarantee/Guarantee";
import { useNavigate, Link } from "react-router-dom";


interface NewDesktopIntroductionProps {
    scrollToRef: () => void;
}

export const NewDesktopIntroduction = (props: NewDesktopIntroductionProps) => {
    const navigate = useNavigate();
    
    const features = [
        {text: "Pomożemy Ci zdać sesję na studiach", icon: "🚀"},
        {text: "Oszczędź setki złotych na korepetycjach", icon: "💰"},
        {text: "Kursy opracowane przez pasjonatów nauki", icon: "🧑‍💻"},
        {text: "Filmy, quizy, zadania otwarte, notatki", icon: "📚"}
    ]


    const laptopFeatures = [
        { description: "Animowane lekcje z przedmiotów inżynierskich.", link: "laptop_2_2", feature: "filmy" },
        { description: "Quizy sprawdzające wiedzę do każdej lekcji.", link: "laptop_3", feature: "quizy" },
        { description: "Zadania otwarte rozszerzające wiedzę z lekcji.", link: "laptop_4", feature: "zadania otwarte" },
        { description: "Notatki przygotowane do każdej lekcji.", link: "laptop_5", feature: "notatki" },
    ];


    const [activeIndex, setActiveIndex] = useState(0);

    const [displayGuarantee, setDisplayGuarantee] = useState(false);
    
    const handleMenuTurn = (state: boolean) => {
        let html = document.querySelector('html');
        
        if(state) {
            setDisplayGuarantee(true);
            if(html !== null) html.classList.add('html-not-scrollable');
            document.body.classList.add('body-not-scrollable');
        } else {
            setDisplayGuarantee(false);
            if(html !== null) html.classList.remove('html-not-scrollable');
            document.body.classList.remove('body-not-scrollable');
        }
    } 

    const handleRightButtonClick = () => {
        if(activeIndex < (features.length-1))
            setActiveIndex(activeIndex +1);
    }

    const handleLeftButtonClick = () => {
        if(activeIndex > 0) setActiveIndex(activeIndex - 1);
    }


    return (
        <>
            {
                displayGuarantee && 
                <GuaranteePopup handleMenuTurn={handleMenuTurn}/>
            }

            <div className="main-page__NewDesktopIntroduction">
                <div className="main-page__NewDesktopIntroduction__left-section">
                    <h1>
                   <b>Jedyne kursy</b> na studia jakich potrzebujesz
                </h1>

                <h2>
                    Przekonaj się, że nie warto przepłacać za korepetycje i zdaj studia ucząc się z nasesje<b>.com</b>.
                </h2>

                <div className="main-page__NewDesktopIntroduction__left-section__bullets">
                    {
                        features.map((el, index) => 
                            <div className="main-page__NewDesktopIntroduction__left-section__bullets__bullet">
                                <span className="main-page__NewDesktopIntroduction__left-section__bullets__bullet__icon">
                                    {el.icon}
                                </span>
                                <span className="main-page__NewDesktopIntroduction__left-section__bullets__bullet__text">
                                    {el.text}
                                </span>
                            </div>   
                        )
                    }

                    
                </div>

                <div className="main-page__NewDesktopIntroduction__left-section__buttons">
                    <button className="find-out-more" onClick={props.scrollToRef}>
                        Poznaj ofertę
                    </button>
                    <Link 
                        to="/shop" 
                        className="go-to-shop"
                        onClick={() => window.scrollTo(0, 0)}
                        title="Sklep"
                    >
                        Znajdź swój kurs
                    </Link>
                    <div className="main-page__NewDesktopIntroduction__left-section__buttons__guarantee"  onClick={() => handleMenuTurn(true)}>
                        <span>7-dniowa gwarancja na każdy kurs</span> <InfoIcon400/>
                    </div>
                </div>
            </div>
            <div className="main-page__NewDesktopIntroduction__right-section">
                <div className="main-page__NewDesktopIntroduction__right-section__feature-wrapper">
                    <img alt="" src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/laptops/${laptopFeatures[activeIndex].link}.webp`}></img>
                    <div className="main-page__NewDesktopIntroduction__right-section__buttons">
                        <button
                            className={`main-page__NewDesktopIntroduction__right-section__buttons__button-left ${(activeIndex === 0) && 'inactive'}`}
                            onClick={handleLeftButtonClick}
                        >
                            <ArrowForwardIos400/>
                        </button>
                        <button 
                            className={`main-page__NewDesktopIntroduction__right-section__buttons__button-right ${(activeIndex === (features.length - 1) && 'inactive')}`}
                            onClick={handleRightButtonClick}
                        >
                            <ArrowForwardIos400/>
                        </button>
                    </div>
                </div>
                <div className="main-page__NewDesktopIntroduction__right-section__subtitle">
                    <span>
                        {laptopFeatures[activeIndex].description}
                    </span>
                </div>



            </div>
        </div>
        </>
    )
}