import { BuyAllCoursesData, SingleCourse } from "shared/coursesPreviewDto";
import { FeaturedSeasonalAndGifts } from "../../Icons/FeaturedSeasonalAndGifts";
import { CheckoutCloseButton } from "../checkoutCloseButton/CheckoutCloseButton";
import { useEffect, useRef, useState } from "react";
import { ExtensionIcon400 } from "../../Icons/ExtensionIcon400";
import { NeurologyIcon400 } from "../../Icons/NeurologyIcon400";
import { PlayCircleIcon400 } from "../../Icons/PlayCircleIcon400";
import { ScheduleIcon400 } from "../../Icons/ScheduleIcon400";
import { useTranslation } from "react-i18next";
import { getPriceAfter } from "../../../utilities/pricing/discount";
import { BookIcon400 } from "../../Icons/BookIcon400";
import MediaQuery from "react-responsive";
import { BulletPoint } from './BulletPoint';

interface CoursePropertyUpsellProps {
    icon: JSX.Element,
    prop: number | null | string,
    text: string,
    highlight: boolean
}

const CoursePropertyUpsell = (props: CoursePropertyUpsellProps) => {

    return (
        <div className={`checkout__buy-all-courses-upsell__property ${props.highlight ? '--highlight' : '--normal'}`}>
            {props.icon}
            <span className="checkout__buy-all-courses-upsell__property__text">
                <span>{props.prop ? props.prop : ""}</span><span className={'--heavy'}>{props.text}</span>
            </span>

        </div>    
    );
}

interface BuyAllCoursesUpsellProps {
    isLoggedIn: boolean;
    setCloseUpsell: React.Dispatch<React.SetStateAction<boolean>>;
    setBuyNowClicked: React.Dispatch<React.SetStateAction<boolean>>;
    buyAllCoursesData: BuyAllCoursesData;
    courseIcon: string;
    coursePrice: number;
    courseName: string;
    buyAllCourses: boolean;
    setBuyAllCourses: React.Dispatch<React.SetStateAction<boolean>>;
    clickedNextButton: boolean;
    setClickedNextButton: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BuyAllCoursesUpsell = (props: BuyAllCoursesUpsellProps) => {
    const allCoursesImage = "https://na-sesje-public.s3.eu-central-1.amazonaws.com/course-icons/wszystkie_kursy.png";
    const { t } = useTranslation();

    type ImageType = "allCourses" | "singleCourse";

    const getImageClassFor = (imageType: ImageType) => {
        const isAllCourses = imageType === "allCourses";
        const shouldHighlight = isAllCourses ? props.buyAllCourses : !props.buyAllCourses;
        return shouldHighlight ? "--highlight" : "--dim";
    }

    const getSumPrice = () => {
        const sum = props.buyAllCoursesData.courses.reduce((acc, course) => acc + getPriceAfter(course.discount, course.coursePrice), 0);
        return sum;
    }

    const containerRef = useRef<HTMLDivElement>(null);
    const additionalContainerRef = useRef<HTMLDivElement>(null);
    const [height, setHeight] = useState<string>('0px');
    const topRef = useRef<HTMLDivElement>(null);
    const firstSectionRef = useRef<HTMLDivElement>(null);
    const secondSectionRef = useRef<HTMLDivElement>(null);

    const defaultCourse = props.buyAllCoursesData.courses.find(
        (course) => course.courseName === props.courseName
    );
    
    const additionalCourses = props.buyAllCoursesData.courses.filter(
        (course) => course.courseName !== props.courseName
    );


    useEffect(() => {
        if (containerRef.current) {
            if (props.buyAllCourses) {
                setHeight(`${containerRef.current.scrollHeight}px`);
            } else {
                setHeight('0px');
            }
        }
    }, [props.buyAllCourses, props.buyAllCoursesData.courses]);

    useEffect(() => {
        if (additionalContainerRef.current) {
            if (props.buyAllCourses) {
                setHeight(`${additionalContainerRef.current.scrollHeight}px`);
            } else {
                setHeight('0px');
            }
        }
    }, [props.buyAllCourses, additionalCourses]);

    const getPropsCoursesCount = () => {
        if(props.buyAllCourses){
            return props.buyAllCoursesData.courses.length;
        } else {
            return 1;
        }
    }

    const getPropsLessonCount = () => {
        if(props.buyAllCourses){
            return props.buyAllCoursesData.courses.reduce((acc, course) => acc + course.lessonCount, 0);
        } else {
            return defaultCourse?.lessonCount ?? 0;
        }
    }

    const getPropsTime = () => {
        if(props.buyAllCourses){
            return props.buyAllCoursesData.courses.reduce((acc, course) => acc + course.time, 0);
        } else {
            return defaultCourse?.time ?? 0;
        }
    }

    const getPropsClosedTestsCount = () => {
        if(props.buyAllCourses){
            return props.buyAllCoursesData.courses.reduce((acc, course) => acc + course.closedTestsCount, 0);
        } else {
            return defaultCourse?.closedTestsCount ?? 0;
        }
    }

    const getPropsOpenTestsCount = () => {
        if(props.buyAllCourses){
            return props.buyAllCoursesData.courses.reduce((acc, course) => acc + course.openTestsCount, 0);
        } else {
            return defaultCourse?.openTestsCount ?? 0;
        }
    }

    const mapPluralSingular = (count: number) => {
        let numberText = "";
        if(count === 1) {
            numberText = "kurs";
        } else if(count > 1 && count < 5) {
            numberText = "kursy";
        } else {
            numberText = "kursów";
        }

        return numberText;
    }

    const mapCountToText = (count: number) => {
        const numbers: any = {
            1: "Jeden",
            2: "Dwa",
            3: "Trzy",
            4: "Cztery",
            5: "Pięć",
            6: "Sześć",
            7: "Siedem",
            8: "Osiem",
            9: "Dziewięć",
            10: "Dziesięć",
            11: "Jedenaście",
            12: "Dwanaście",
            13: "Trzynaście",
            14: "Czternaście",
            15: "Piętnaście",
            16: "Szesnaście",
            17: "Siedemnaście",
            18: "Osiemnaście",
            19: "Dziewiętnaście",
            20: "Dwadzieścia",
        }
        
        const number: string = numbers[count];

        const numberText = mapPluralSingular(count);

        return `${number} ${numberText}`;
    }

    const oneCourseClickHandler = () => {
        props.setBuyAllCourses(false);
        if (window.innerWidth <= 950) {
            setTimeout(() => {
                topRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 130); // 300ms matches the transition time in your CSS
        }
    }

    const getButtonText = () => {
        if(props.buyAllCourses) {
            return `Przejdź dalej i zaoszczędź 💰`;
        } else {
            return `Przejdź dalej`;
        }
    }

    return (
    <div className="checkout__buy-all-courses-upsell" ref={topRef}>
        <div>
            <div className="checkout__buy-all-courses-upsell__title">
                <div className="checkout__buy-all-courses-upsell__title__container">
                    <span className="gift-icon">🎁</span>
                    <span> Okazja! {props.buyAllCoursesData.courses.length} {mapPluralSingular(props.buyAllCoursesData.courses.length)} w cenie <span className="--sum-savings--highlight">{props.buyAllCoursesData.coursePack.price} zł</span>.</span>
                </div>
                <CheckoutCloseButton setBuyNowClicked={props.setBuyNowClicked} isBuyAllCoursesUpsell={true}/>
            </div>
            <MediaQuery minWidth={951}>
                <div className="checkout__buy-all-courses-upsell__title__secondary-text">
                    Kupując paczkę, otrzymasz <span className="--heavy">wszystkie kursy</span> Nasesje w <span className="--sum-savings--highlight"> promocyjnej </span> cenie.
                </div>
            </MediaQuery>
            <div className="checkout__buy-all-courses-upsell__sections-wrapper">
                <div className="checkout__buy-all-courses-upsell__sections-wrapper__first-section" ref={firstSectionRef}>
                    <MediaQuery maxWidth={950}>
                        <div>
                            Kupując paczkę, otrzymasz <span className="--heavy">wszystkie kursy</span> Nasesje w <span className="--sum-savings--highlight">promocyjnej</span> cenie.
                        </div>
                    </MediaQuery>

                    <div className="checkout__buy-all-courses-upsell__courses-list first-list">
                        <MediaQuery minWidth={951}>
                            <h3>Lista kursów</h3>
                        </MediaQuery>
                        <MediaQuery maxWidth={950}>
                            <h3>Zawartość {props.buyAllCourses ? "paczki" : "kursu"}</h3>
                        </MediaQuery>
                        
                        <MediaQuery maxWidth={950}>
                            <div className="checkout__buy-all-courses-upsell__course-properties--wrapper">
                                {
                                    [           
                                        { icon: <BookIcon400/>, prop: getPropsCoursesCount(), text: ` ${props.buyAllCourses ? t("kurs.preview.previewPage.courseProperties.coursesPlural") : t("kurs.preview.previewPage.courseProperties.coursesSingular")}`, highlight: true },
                                        { icon: <PlayCircleIcon400/>, prop: getPropsLessonCount(), text: ` ${t("kurs.preview.previewPage.courseProperties.lessons")}`, highlight: false },
                                        { icon: <ScheduleIcon400/>, prop: `${getPropsTime().toString()}h`, text: t("kurs.preview.previewPage.courseProperties.hoursOfRecordingsShort"), highlight: false },
                                        { icon: <ExtensionIcon400/>, prop: getPropsClosedTestsCount(), text: ` ${t("kurs.preview.previewPage.courseProperties.quizes")}`, highlight: false },
                                        { icon: <NeurologyIcon400/>, prop: getPropsOpenTestsCount(), text: ` ${t("kurs.preview.previewPage.courseProperties.assignments")}`, highlight: false }

                                    ].map((el, index) => 
                                        <CoursePropertyUpsell key={index} icon={el.icon} prop={el.prop} text={el.text} highlight={el.highlight}/>)
                                }
                            </div>
                        </MediaQuery>
                        <div className="checkout__buy-all-courses-upsell__courses-list second-list">


                            <div className="checkout__buy-all-courses-upsell__courses-list__header">
                                <span className="checkout__buy-all-courses-upsell__courses-list__header__name">Nazwa kursu</span>
                                <span className="checkout__buy-all-courses-upsell__courses-list__header__price">Cena bazowa</span>
                            </div>

                            {defaultCourse && (
                                <div className="checkout__buy-all-courses-upsell__courses-list__item">
                                    <span className="checkout__buy-all-courses-upsell__courses-list__item__name">
                                        <span className="--circle"></span> {defaultCourse.courseName}
                                    </span>
                                    <span className={`checkout__buy-all-courses-upsell__courses-list__item__price ${props.buyAllCourses ? '--strike-through' : ''}`}>
                                        {getPriceAfter(defaultCourse.discount, defaultCourse.coursePrice)} zł
                                    </span>
                                </div>
                            )}
                            {/* Additional courses container with animated height */}
                            <div
                                ref={additionalContainerRef}
                                className="checkout__buy-all-courses-upsell__courses-list__items-container"
                                style={{
                                    height: height,
                                    overflow: 'hidden',
                                    transition: 'height 0.1s ease-in-out',
                                }}
                                >
                                    {additionalCourses.map((course, index) => (
                                        <div
                                            className="checkout__buy-all-courses-upsell__courses-list__item"
                                            key={index}
                                        >
                                            <span className="checkout__buy-all-courses-upsell__courses-list__item__name">
                                                <span className="--circle"></span> {course.courseName}
                                            </span>
                                            <span className="checkout__buy-all-courses-upsell__courses-list__item__price --strike-through">
                                                {getPriceAfter(course.discount, course.coursePrice)} zł
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="checkout__buy-all-courses-upsell__sections-wrapper__second-section" ref={secondSectionRef}>
                        <MediaQuery minWidth={951}>
                            <div className="checkout__buy-all-courses-upsell__courses-list first-list">
                                <h3>Zawartość {props.buyAllCourses ? "paczki" : "kursu"}</h3>
                                <div className="line"></div>
                                <div className="checkout__buy-all-courses-upsell__course-properties--wrapper">
                                    {
                                        [           
                                            { icon: <BookIcon400/>, prop: getPropsCoursesCount(), text: ` ${props.buyAllCourses ? t("kurs.preview.previewPage.courseProperties.coursesPlural") : t("kurs.preview.previewPage.courseProperties.coursesSingular")}`, highlight: true },
                                            { icon: <PlayCircleIcon400/>, prop: getPropsLessonCount(), text: ` ${t("kurs.preview.previewPage.courseProperties.lessons")}`, highlight: false },
                                            { icon: <ScheduleIcon400/>, prop: `${getPropsTime().toString()}h`, text: t("kurs.preview.previewPage.courseProperties.hoursOfRecordingsShort"), highlight: false },
                                            { icon: <ExtensionIcon400/>, prop: getPropsClosedTestsCount(), text: ` ${t("kurs.preview.previewPage.courseProperties.quizes")}`, highlight: false },
                                            { icon: <NeurologyIcon400/>, prop: getPropsOpenTestsCount(), text: ` ${t("kurs.preview.previewPage.courseProperties.assignments")}`, highlight: false }

                                        ].map((el, index) => 
                                            <CoursePropertyUpsell key={index} icon={el.icon} prop={el.prop} text={el.text} highlight={el.highlight}/>)
                                    }
                                </div>
                            </div>
                        </MediaQuery>
                    <div className="checkout__buy-all-courses-upsell__sum-savings">
                        <div className="checkout__buy-all-courses-upsell__sum-savings__container">
                        {props.buyAllCourses ? 
                        
                            <>
                                <BulletPoint icon="🚀">
                                    To wszystko w cenie <span className="--sum-strike-through">{getSumPrice()} zł</span> <span className="--sum-savings--highlight">{props.buyAllCoursesData.coursePack.price} zł</span>
                                </BulletPoint>
                                <BulletPoint icon="💰">
                                    Kupując paczkę, <span className="--sum-savings--heavy">oszczędzasz </span>aż <span>{getSumPrice() - props.buyAllCoursesData.coursePack.price} zł!</span>
                                </BulletPoint>
                            </> :

                            <>
                                <BulletPoint icon="🎁">
                                    Wybierz paczkę kursów i zapłać tylko <span className="--sum-savings--highlight">{props.buyAllCoursesData.coursePack.price} zł</span> za wszystkie kursy!
                                </BulletPoint>
                            </>
                        }
                        
                        </div>
                    </div>

                
                    <MediaQuery maxWidth={950}>
                        <div className="checkout__buy-all-courses-upsell__image__wrapper">
                            <div className="checkout__buy-all-courses-upsell__image--container" onClick={() => props.setBuyAllCourses(true)}>
                                <div className={`label label-top ${props.buyAllCourses ? '--highlight' : '--dim'}`}>Paczka kursów</div>
                                <img className={getImageClassFor("allCourses")} src={allCoursesImage} alt="Paczka kursów" />
                                <div className={`label label-bottom ${props.buyAllCourses ? '--highlight' : '--dim'}`}>Najlepsza oferta</div>
                            </div>
                            <div className="checkout__buy-all-courses-upsell__image--container" onClick={() => oneCourseClickHandler()}>
                                <div className={`label label-top ${props.buyAllCourses ? '--dim' : '--highlight'}`}>Jeden kurs</div>
                                <img className={getImageClassFor("singleCourse")} src={props.courseIcon} alt="Paczka kursów" />
                            </div>
                        </div>
                    </MediaQuery>

                    <div className="checkout__buy-all-courses-upsell__options">
                        <div 
                            className={`checkout__buy-all-courses-upsell__options__option ${props.buyAllCourses ? '--selected' : ''}`}
                            onClick={() => props.setBuyAllCourses(true)}
                        >
                            <input
                                type="radio"
                                value="wszystkie kursy nasesje"
                                checked={props.buyAllCourses}
                                onChange={() => props.setBuyAllCourses(true)}
                            />
                            <label>
                                <span>{mapCountToText(props.buyAllCoursesData.courses.length)} za </span> <span className="--sum-strike-through"> {getSumPrice()} zł</span> <span className="--sum-savings--highlight">{props.buyAllCoursesData.coursePack.price} zł</span>
                            </label>
                        </div>

                        <div 
                            className={`checkout__buy-all-courses-upsell__options__option ${!props.buyAllCourses ? '--selected' : ''}`}
                            onClick={() => oneCourseClickHandler()}
                        >
                            <input
                                type="radio"
                                value="pojedynczy kurs"
                                checked={!props.buyAllCourses}
                                onChange={() => props.setBuyAllCourses(false)}
                            />
                            <label>
                                <span className="--low-opacity">Jeden kurs: {props.courseName} za {props.coursePrice} zł</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={`checkout__signup-box__finalize-transaction checkout__signup-box__finalize-transaction--upsell`}>
            <div 
                className={`checkout__signup-box__finalize-transaction__button checkout__signup-box__finalize-transaction__button--active upsell-button-desktop`}
                onClick={() => {
                    props.setCloseUpsell(true)
                    props.setClickedNextButton(true)
                }}
            >
                <span>
                    {getButtonText()}
                </span>
            </div>
        </div> 
    </div>
    )
}