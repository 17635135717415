import { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { GlobalDataContext } from "../../../components/global/globalDataContext/GlobalDataContextProvider";
import { BookIcon400 } from "../../Icons/BookIcon400";
import { CheckIcon400 } from "../../Icons/CheckIcon400";
import { Payments400Icon } from "../../Icons/Payments400Icon";
import { PlayCircleIcon400 } from "../../Icons/PlayCircleIcon400";
import "./coursePackage.scss";

export const CoursePackage = () => {

    const navigate = useNavigate();
    const globalDataContext = useContext(GlobalDataContext);

    const avaiableCourses = [
        "Całki nieoznaczone",
        "Granice ciągów i funkcji",
        "Dynamika",
        "Statystyka",
        "Macierze i wektory",
        "Liczby zespolone",
        "Liczby binarne i algebra Boole'a",
        "Teoria języków formalnych"
    ];

    const courseIcons = [
        "calkiNieoznaczone_result.webp",
        "dynamics.png",
        "limits.webp",
        "matrixes-and-vectors.webp",
        "comples_numbers.webp",
        "LiczbyBinarneIAlgebraBoolea.webp",
        "formal-languages-theory.webp",
        "statistics.webp"
    ]

    return (
        <div className="main-page__course-package">
            

            <div className="main-page__course-package__left">
                <h2>Paczka wszystkich kursów 🎁</h2>
                <div className="main-page__course-package__left__properties">
                    <div className="main-page__course-package__left__properties__left">
                        <div className="left">
                            <span className="left__description">Liczba kursów:</span>
                            <span className="left__number">8 <b className="mobile-hidden">kursów</b></span>
                        </div>
                        <BookIcon400/>
                    </div>
                    <div className="main-page__course-package__left__properties__middle">
                        <div className="left">
                            <span className="left__description">Cena za kurs:</span>
                            <span className="left__number">18.63 <b>zł</b></span>
                        </div>
                        <Payments400Icon/>
                    </div>
                    <div className="main-page__course-package__left__properties__right">
                        <div className="left">
                            <span className="left__description">Liczba lekcji:</span>
                            <span className="left__number">171</span>
                        </div>
                        <PlayCircleIcon400/>
                    </div>
                </div>

                <div className="main-page__course-package__left__offer">
                    <span>Wybierając paczkę kursów zyskujesz dostęp do wszystkich 8 kursów Nasesje na okres 12 miesięcy.</span>
                                        
                </div>


                <h3>w cenie <b className="green">149zł</b> zamiast <b className="red">1087zł</b></h3>
                <div className="main-page__course-package__left__offer__button">
                    <Link 
                        to={`/coursePreview/${globalDataContext.allCoursesBundleId}/allCoursesBundle`}
                        title="Paczka wszystkich kursów"
                    >
                        Zobacz ofertę
                    </Link>
                </div>

            </div>

            <div className="main-page__course-package__right">
                <div className="main-page__course-package__right__wrapper">
                    <div className="main-page__course-package__right__course-grid">
                        {
                            courseIcons.map((el, index) => 
                                <div className="grid-item" key={index}>
                                    <img 
                                        alt="ikona kursu" 
                                        src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/course-icons/${el}`}
                                    />
                                </div>
                            )
                        }
                    </div>

                    <div className="main-page__course-package__right__courses">
                        {
                            avaiableCourses.map((el, index) => 
                                <div className="course" key={index}>
                                    <span>{el}</span>
                                    <CheckIcon400/>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>


        </div>
    )

}
