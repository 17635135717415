export const YouAreNotAlone = () => {
    return (
        <>
            <h2>
                Nie jesteś sam! 
            </h2>
            <div className="course-preview__main-section__left-panel__text-section__consultations">
                <span className="course-preview__main-section__left-panel__text-section__consultations__main-text">
                    Pozostajesz <b>pod stałą opieką</b> twórców kursu. 
                </span>
                <br/>
                <div className="course-preview__main-section__left-panel__text-section__consultations__second-text">
                    Jeśli masz problem z zadaniem, śmiało napisz do nas z pytaniem ✏️
                </div>
                <div className="course-preview__main-section__left-panel__text-section__consultations__second-text">
                    Odpowiadamy na pytania raz w tygodniu.
                </div>
            </div>
        </>
    )
}