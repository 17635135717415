import { Dispatch, SetStateAction, useState } from "react";
import { Block } from "shared/block";

interface DisplayedOptionProps {
    text: string,
    myIndex: number,
    tableOfContentsRef: HTMLDivElement | null;
    isActive: boolean;
}

export const DisplayedOption = (props: DisplayedOptionProps) => {

    const handleClick = () => {
        if(props.tableOfContentsRef === null) return;

        props.tableOfContentsRef.scrollIntoView({ behavior: 'smooth' });
    }
    
    const handleClassName = () => {
        const base = "course-preview__main-section__left-panel__panels-selector__option";
        let finalClass = `not-selectable ${base} ${base}--${(props.isActive === true) ? "active" : "inactive"}`;

        return finalClass;
    }

    return (
        <div 
            onClick={() => { 
                    handleClick()
            }}
            className={handleClassName()}
        >
            <span>{props.text}</span>
        </div>
    );
}  