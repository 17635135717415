import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from 'react-router-dom';

import "./coursePreview.scss";

import { LandingFooter } from "../LandingFooter/LandingFooter";
import { LandingNavbar } from "../LandingNavbar/LandingNavbar";
import { CourseSectionsWrapper } from "./SectionsWrapper/CourseSectionsWrapper/CourseSectionsWrapper";
import { Checkout } from "../Checkout/Checkout";

import { CoursePreviewPageDto } from "../../../../shared/coursesPreviewDto";
import { get } from "../../utilities/rest/apiClient";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { NotifyMeWhenAvailable } from "./NotifyMeWhenAvailable/NotifyMeWhenAvailable";
import { usePageTracking } from "../../hooks/analytics/usePageTracking";
import ReactGA from "react-ga4";

import {registerEvent } from "../../hooks/analytics/registerEvent"
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";
import { handleStatsRegister } from "../../utilities/pageStatsLogging";
import { Cookie } from "../MainPage/Cookie/Cookie";
import MediaQuery from "react-responsive";
import "../../utilities/analytics/tiktok/tiktok" 
import * as tiktok from "../../utilities/analytics/tiktok/tiktok";

import { PlayCircleIcon400 } from "../Icons/PlayCircleIcon400";
import { ScheduleIcon400 } from "../Icons/ScheduleIcon400";
import { ExtensionIcon400 } from "../Icons/ExtensionIcon400";
import { NeurologyIcon400 } from "../Icons/NeurologyIcon400";
import { PlayCircleIcon200 } from "../Icons/PlayCircleIcon200";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Seo } from "../../components/seo/Seo";
import { getPriceAfter } from "../../utilities/pricing/discount";
import { SellIcon400 } from "../Icons/SellIcon400";
import { BundleContent, BundleSectionsWrapper } from "./SectionsWrapper/BundleSectionsWrapper/BundleSectionsWrapper";
import { BuyNowButton } from "./BuyNowButton/BuyNowButton";
import { BookIcon400 } from "../Icons/BookIcon400";
import { LdsRoller } from "../PaymentConfirmation/LdsRoller";

interface CoursePropertyProps {
    icon: JSX.Element,
    prop: number | null,
    text: string,
    highlight: boolean
}

const CourseProperty = (props: CoursePropertyProps) => {

    return (
        <div className={`course-preview__main-section__left-panel__course-properties__property ${props.highlight ? "--highlight" : "--normal"}`}>
            {props.icon}
            <span className="course-preview__main-section__left-panel__course-properties__property__text">
                {props.prop ? props.prop : ""}{props.text}
            </span>

        </div>    
    );
}

interface CoursePreviewProps {
    isAllCoursesBundle: boolean;
}

export const CoursePreview = (props: CoursePreviewProps) => {
    usePageTracking("coursePreview");
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const authContext = useContext(AuthContext);

    const location = useLocation();

    const isMobile = useIsMobile();


    const [coursePreviewDto, setCoursePreviewDto] = useState<CoursePreviewPageDto>({} as CoursePreviewPageDto);
    const [isLoading, setIsLoading] = useState(true);
    const [lessonCount, setLessonCount] = useState(0);
    const { courseId } = useParams();

    const [basicOptionSelected, setBasicOptionSelected] = useState(false); 
    const [enchancedOptionSelected, setEnchancedOptionSelected] = useState(false); 
    const [activeSectionIndex, setActiveSectionIndex] = useState(0);

    const [selectHighlight, setSelectHighlight] = useState(false);
    const [buyNowClicked, setBuyNowClicked] = useState(false);


    const targetRef = useRef<HTMLDivElement | null>(null);
    const rightPanelRef = useRef<HTMLDivElement | null>(null);
    const footerRef = useRef<HTMLDivElement | null>(null);
    const [bottomReached, setBottomReached] = useState(false);

    const [isFixed, setIsFixed] = useState(false);
    const [initialOffsetTop, setInitialOffsetTop] = useState(0);

    const [tresholdScrollPosition, setTresholdScrollPosition] = useState(0);

    const [howManyPixelsToScroll, setHowManyPixelsToScroll] = useState(40);

    useEffect(() => {
        if (rightPanelRef.current) {
            setInitialOffsetTop(rightPanelRef.current.offsetTop); // Store initial top offset on load
        }

        const handleScroll = () => {
            if (rightPanelRef.current) {
                const offsetTop = rightPanelRef.current.getBoundingClientRect().top;
                const scrollPosition = window.scrollY;

                // Fix the panel when scrolling down and it reaches the top of the viewport
                if (offsetTop <= howManyPixelsToScroll && !isFixed) {
                    setIsFixed(true);
                    setTresholdScrollPosition(scrollPosition);
                } 
                // Reset the panel when scrolling back up past its initial offset position
                if(isFixed){
                    if (scrollPosition < tresholdScrollPosition) {
                        setIsFixed(false);
                    }
                }

                if(footerRef.current === null) return;

                const footerPosition = footerRef.current?.getBoundingClientRect().top + window.scrollY;
                if (footerPosition && scrollPosition + rightPanelRef.current.offsetHeight >= footerPosition) {
                    setBottomReached(true);
                } else {
                    setBottomReached(false);
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isFixed, initialOffsetTop, footerRef, bottomReached, tresholdScrollPosition]);


    useEffect(() => {
        if(window.innerWidth <= 950) {
            setActiveSectionIndex(1);
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);

        (async ()=> {
            try {
              let data: CoursePreviewPageDto;

              if(props.isAllCoursesBundle) {
                data = await get<CoursePreviewPageDto>(`reactRoutes/getCoursesPreviewForAllCoursesBundle/${courseId}/${i18n.language}`);
                setLessonCount(data.buyAllCoursesData.courses.reduce((total, course) => total + course.lessonCount, 0));
              } else {
                data = await get<CoursePreviewPageDto>(`reactRoutes/getCoursesPreviewForShopPage/${courseId}/${i18n.language}`);
                setLessonCount(data.courseContent.reduce((total, chapter) => total + chapter.lessons.length, 0));
              }
    
              setCoursePreviewDto(data);
              
              setIsLoading(false);
                
              const courseIdStr = courseId ? courseId : 'undefined';
              tiktok.ViewContent(data.courseName, courseIdStr);
    
            } catch(error) {
              //TODO other errors
            }
          })()
    }, [])

    const prosText = [
        `${t("kurs.preview.previewPage.prosText.moreThan")} ${coursePreviewDto.time} ${t("kurs.preview.previewPage.prosText.hoursOfRecordings")}.`,
        t("kurs.preview.previewPage.prosText.interactiveTests"),
        t("kurs.preview.previewPage.prosText.openAssignmentsBundles"),
        t("kurs.preview.previewPage.prosText.notesAccessAfterEachLesson"),
        t("kurs.preview.previewPage.prosText.interactiveFinalTest"),
        t("kurs.preview.previewPage.prosText.materialsAccess")
    ];

    const handleButtonName = (left: boolean) => {
        const base = "not-selectable course-preview__main-section__right-panel__additional-materials__buttons__button";
        let finalClass = `not-selectable ${base} ${base}--${left ? "left" : "right"}`;
        
        const isActive = left ? basicOptionSelected : enchancedOptionSelected;
        finalClass += ` ${base}--${isActive ? 'active' : 'inactive'}`;
        return finalClass;
    }

    const handleBuyNowClass = () => {
        const base = "course-preview__main-section__right-panel__buttons__button";
        let finalClass = `not-selectable ${base} ${base}--left`;

        const isActive = basicOptionSelected || enchancedOptionSelected || coursePreviewDto.coursePubliclyAvailable === false || coursePreviewDto.explanationsAvailable == false;

        finalClass += ` ${base}--${isActive? "active" : "inactive"}`;

        return finalClass;
    }

    const handleBuyNowClick = () => {


        handleStatsRegister(
            location.pathname,
            false,
            false,
            true,
            false,
            false,
        );
        
        registerEvent(authContext, `buy now - ${coursePreviewDto.courseName}`, `buy now - ${coursePreviewDto.courseName}`, `buy now - ${coursePreviewDto.courseName}`);
        
        if(basicOptionSelected || enchancedOptionSelected || coursePreviewDto.coursePubliclyAvailable === false || coursePreviewDto.explanationsAvailable === false) {
            setBuyNowClicked(true);

            let html = document.querySelector('html');
            if(html !== null) html.classList.add('html-not-scrollable');
            document.body.classList.add('body-not-scrollable');


        } else {
            setSelectHighlight(true);

            if (targetRef.current) {
                targetRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }

    const getFullPrice = () => {
        return coursePreviewDto.price + (enchancedOptionSelected ? coursePreviewDto.recordingsPrice : 0);
    }

    const getCourseProperties = useMemo(() => {
        if(isLoading) return [];
        
        const properties = [
            { 
                icon: <PlayCircleIcon400/>, 
                prop: lessonCount, 
                text: ` ${t("kurs.preview.previewPage.courseProperties.lessons")}`, 
                highlight: false 
            },
            { 
                icon: <ScheduleIcon400/>, 
                prop: coursePreviewDto.time, 
                text: t("kurs.preview.previewPage.courseProperties.hoursOfRecordings"), 
                highlight: false 
            },
            { 
                icon: <ExtensionIcon400/>, 
                prop: coursePreviewDto.closedTestsCount, 
                text: ` ${t("kurs.preview.previewPage.courseProperties.quizes")}`, 
                highlight: false 
            },
            { 
                icon: <NeurologyIcon400/>, 
                prop: coursePreviewDto.openTestsCount, 
                text: ` ${t("kurs.preview.previewPage.courseProperties.assignments")}`, 
                highlight: false 
            }
        ];

        if (props.isAllCoursesBundle) {
            properties.unshift({ 
                icon: <BookIcon400/>, 
                prop: coursePreviewDto.buyAllCoursesData.courses.length, 
                text: ` ${t("kurs.preview.previewPage.courseProperties.coursesPlural")}`, 
                highlight: true 
            });
        }

        return properties;
    }, [lessonCount, coursePreviewDto, props.isAllCoursesBundle, coursePreviewDto.buyAllCoursesData, isLoading]);

    if(isLoading) return <></> //TODO fallback

    return(
        <div className="course-preview">
            {
                buyNowClicked ? "" : <LandingNavbar/>
            }

            <Seo
              title={`Kurs ${coursePreviewDto.courseName} : Nasesje.com`}
              description={coursePreviewDto.shortDescription}
            />

            
            {
                (buyNowClicked && coursePreviewDto.coursePubliclyAvailable) && 
                    <Checkout
                        setBuyNowClicked={setBuyNowClicked}
                        basicOptionSelected={basicOptionSelected}
                        enchancedOptionSelected={enchancedOptionSelected}
                        courseID={ courseId }
                        discountedPrice={ getPriceAfter(coursePreviewDto.discount, getFullPrice())}
                        originalPrice={coursePreviewDto.price}
                        buyAllCoursesData={coursePreviewDto.buyAllCoursesData}
                        courseIcon={coursePreviewDto.courseIcon}
                        courseName={coursePreviewDto.courseName}
                        isAllCoursesBundleDirectlyFromShop={props.isAllCoursesBundle}
                    />
            }

            {
                (buyNowClicked && !coursePreviewDto.coursePubliclyAvailable) && 
                <NotifyMeWhenAvailable
                    setBuyNowClicked={setBuyNowClicked}
                />
            }

            <div className="course-preview__main-section">
                <div className="course-preview__main-section__left-panel">

                    <div className="course-preview__main-section__left-panel__headline">
                        <span>
                            {coursePreviewDto.courseName}
                        </span>
                    </div>
                    <div className="responsive-container course-preview__main-section__left-panel__responsive-container">
                        <div className="course-preview__main-section__left-panel__responsive-container__loading">
                            <LdsRoller/>
                        </div>
                        <iframe
                            src={coursePreviewDto.introductionVideo}
                            frameBorder="0" allow="fullscreen; picture-in-picture" allowFullScreen
                            title="file_example_MP4_1920_18MG">    
                        </iframe>
                    </div>
    
                    <MediaQuery minWidth={951}>
                        <div className="course-preview__main-section__left-panel__course-properties">
                            {
                                getCourseProperties.map((el, index) => 
                                    <CourseProperty key={index} icon={el.icon} prop={el.prop} text={el.text} highlight={el.highlight}/>)
                            }
                        </div>
                    </MediaQuery>

                    <div className="display-on-desktop">
                        {
                            props.isAllCoursesBundle ? 
                                <BundleSectionsWrapper
                                    activeSectionIndex={activeSectionIndex} 
                                    setActiveSectionIndex={setActiveSectionIndex}
                                    courseContent={coursePreviewDto.courseContent}
                                    description={coursePreviewDto.fullDescription}
                                    courseRecomendation={coursePreviewDto.courseRecomendation}
                                    fromLanding={false}
                                    courseName={coursePreviewDto.courseName}
                                    buyAllCoursesData={coursePreviewDto.buyAllCoursesData}
                                    handleBuyNowClick={handleBuyNowClick}
                                />
                            :
                                <CourseSectionsWrapper 
                                activeSectionIndex={activeSectionIndex} 
                                setActiveSectionIndex={setActiveSectionIndex}
                                courseContent={coursePreviewDto.courseContent}
                                description={coursePreviewDto.fullDescription}
                                courseRecomendation={coursePreviewDto.courseRecomendation}
                                publiclyAvailableChapter={coursePreviewDto.publiclyAvailableChapter}
                                publiclyAvailableLessons={coursePreviewDto.publiclyAvailableLessons}
                                fromLanding={false}
                                courseName={coursePreviewDto.courseName}
                                handleBuyNowClick={handleBuyNowClick}
                        />
                        }
                    </div>


                </div>

                <div ref={targetRef} className="course-preview__main-section__right-panel">
                    
                <MediaQuery maxWidth={950}>
                        <div className="course-preview__main-section__right-panel__price  course-preview__main-section__right-panel__price--mobile">
                            <span className="course-preview__main-section__right-panel__price">
                                { (props.isAllCoursesBundle ? `Cena za paczkę: ` : `Cena za kurs: `)  + `${coursePreviewDto.courseName} - ` }
                            </span>
                            
                            
                            {
                                    coursePreviewDto.discount.isActive ? 
                                    <div className="course-preview__main-section__right-panel__price__discounted-section">
                                        <span className="discounted-price">
                                            <b>{getPriceAfter(coursePreviewDto.discount, getFullPrice())} zł</b>
                                        </span>
                                        <span className="full-price">
                                            <b>{getFullPrice()} zł</b>
                                        </span>
                                    </div> : 
                                    <span>
                                        <b>{getFullPrice()} zł</b>.
                                    </span> 
                            }
                        </div>

                        {
                            coursePreviewDto.discount.isActive && 
                            <div className="course-preview__main-section__right-panel__lowest-price">
                                Najniższa cena z ostatnich 30 dni przed obniżką: {getFullPrice()} zł
                            </div>
                        }


                        <div className="course-preview__main-section__right-panel__buttons">
                            <BuyNowButton 
                                className={handleBuyNowClass()}
                                onClick={handleBuyNowClick}
                            />
                            {
                                !props.isAllCoursesBundle &&
                                <div 
                                    className="not-selectable course-preview__main-section__right-panel__buttons__button course-preview__main-section__right-panel__buttons__button--right"
                                    onClick={() => { 
                                        window.scrollTo(0, 0);

                                        handleStatsRegister(
                                            location.pathname,
                                            false,
                                            false,
                                            false,
                                            false,
                                            true,
                                        );
                                        navigate(`/lessonPreview/${courseId}`);
                                    }}
                                >
                                    <PlayCircleIcon200/>
                                    <span>
                                        {t("kurs.preview.previewPage.watchSampleLesson")}
                                    </span>
                                </div>
                            }
                        </div>
                        {/* <BuyAllCoursesPreviewPage coursePreviewPageDto={coursePreviewDto} currentCoursePriceAfterDiscount={getPriceAfter(coursePreviewDto.discount, getFullPrice())}/> */}
                    </MediaQuery>
                    <div 
                        ref={rightPanelRef} 
                        className={`course-preview__main-section--container ${isFixed && !isMobile ? 'fixed': ''}`}
                        style={
                            (bottomReached && !isMobile) ? { 
                                position: 'absolute',
                                top: footerRef.current !== null && rightPanelRef.current !== null ? footerRef.current.offsetTop - rightPanelRef.current.offsetHeight : 0
                             } : {
                                position: isFixed && !isMobile? 'fixed' : 'relative',
                                top: isFixed && !isMobile? `${howManyPixelsToScroll}px` : 0
                            }
                        }
                        >
                        <div className="course-preview__main-section__right-panel__headline">
                            <span>
                                { props.isAllCoursesBundle ? "🎁 " + coursePreviewDto.courseName : coursePreviewDto.courseName}
                            </span>
                        </div>

                        <div className="course-preview__main-section__right-panel__brief-description">
                            <span>
                                {coursePreviewDto.shortDescription}
                            </span>
                        </div>

                        {
                            coursePreviewDto.discount.isActive && 
                            <div className="course-preview__main-section__right-panel__discount">
                                <div>
                                    <span>
                                        - {100 - (coursePreviewDto.discount.factor * 100)}%
                                    </span>
                                    <SellIcon400/>
                                </div>
                            </div> 
                        }


                        <div className="course-preview__main-section__right-panel__additional-materials">
                            {coursePreviewDto.explanationsAvailable && <div className="course-preview__main-section__right-panel__additional-materials__headline">
                                <span>
                                    {t("kurs.preview.previewPage.tuneYourExperience")}
                                </span>
                            </div>}

                            {coursePreviewDto.explanationsAvailable && <div className="course-preview__main-section__right-panel__additional-materials__text">
                                <span>
                                    {`${t("kurs.preview.previewPage.experienceText.withinTheCourse")} `}
                                    <b>{t("kurs.preview.previewPage.experienceText.additionalRecordings")}</b>
                                    {` ${t("kurs.preview.previewPage.experienceText.withPreciseExplanationOf")}`}  
                                </span>
                            </div>}


                            <MediaQuery maxWidth={950}>
                                <div className="course-preview__main-section__left-panel__course-properties">
                                {
                                    getCourseProperties.map((el, index) => 
                                        <CourseProperty key={index} icon={el.icon} prop={el.prop} text={el.text} highlight={el.highlight}/>)
                                }
                                </div>
                            </MediaQuery>
                            {(coursePreviewDto.coursePubliclyAvailable && coursePreviewDto.explanationsAvailable) &&
                                <>
                                <div className="course-preview__main-section__right-panel__additional-materials__buttons">
                                    <div 
                                        onClick={() => {setEnchancedOptionSelected(false); setBasicOptionSelected(true); } } 
                                        className={handleButtonName(true)}
                                    >
                                        <span className="course-preview__main-section__right-panel__additional-materials__buttons__button__left-text">
                                            {t("kurs.preview.previewPage.basePacket")}
                                        </span>
                                        <span className="course-preview__main-section__right-panel__additional-materials__buttons__button__right-text">
                                            { `+ 0 ${t("kurs.preview.previewPage.currencySymbol")}`}
                                        </span>
                                    </div>
                                    <div 
                                        onClick={() => { setBasicOptionSelected(false); setEnchancedOptionSelected(true); }}
                                        className={handleButtonName(false)}
                                    >
                                        <span className="course-preview__main-section__right-panel__additional-materials__buttons__button__left-text">
                                            {t("kurs.preview.previewPage.additionalRecordings")}
                                        </span>
                                        <span className="course-preview__main-section__right-panel__additional-materials__buttons__button__right-text">
                                            {`+ ${coursePreviewDto.recordingsPrice} ${t("kurs.preview.previewPage.currencySymbol")}`}
                                        </span>
                                    </div>
                                </div>
                                {
                                    (!basicOptionSelected && !enchancedOptionSelected) ? (
                                        <div className={`course-preview__main-section__right-panel__additional-materials__select-option course-preview__main-section__right-panel__additional-materials__select-option--${selectHighlight? "active" : "inactive"}`}>
                                            <div><span>*&nbsp;</span></div>
                                            <div>
                                                <span>
                                                    {t("kurs.preview.previewPage.chooseOneFromTheOptionsAbove")}
                                                </span>
                                            </div>
                                        </div>
                                    ) : ""
                                }
                                </>
                            }

                            
                        </div>

                        <div className="course-preview__main-section__right-panel__pros">
                            <div className="course-preview__main-section__right-panel__pros__headline">
                                <span>
                                    {props.isAllCoursesBundle ? t("kurs.preview.previewPage.whatWillYouFindInOurAllCoursesBundle") : t("kurs.preview.previewPage.whatWillYouFindInOurCourse")}
                                </span>
                            </div>
                            <MediaQuery minWidth={951}>
                            {
                                prosText.map((el, index) => 
                                    <div
                                        key={index}
                                        className="course-preview__main-section__right-panel__pros__point"
                                    >
                                        <div className="course-preview__main-section__right-panel__pros__point__dot"/>
                                        <span className="course-preview__main-section__right-panel__pros__point__text">
                                            {el}
                                        </span>
                                    </div>   
                                )
                            }
                            </MediaQuery>
                        </div>

                        <MediaQuery minWidth={951}>
                            <div className="course-preview__main-section__right-panel__price">
                                <span>
                                    { (props.isAllCoursesBundle ? `Cena za paczkę: ` : `Cena za kurs: `) + `${coursePreviewDto.courseName} - ` }
                                </span>
                                {
                                    coursePreviewDto.discount.isActive ? 
                                    <>
                                        <span className="discounted-price">
                                            <b>{getPriceAfter(coursePreviewDto.discount, getFullPrice())} zł</b>
                                        </span>
                                        <span className="full-price">
                                            <b>{getFullPrice()} zł</b>
                                        </span>
                                    </> : 
                                    <span>
                                        <b>{getFullPrice()} zł</b>.
                                    </span> 
                                }
                            
                            </div>
                            {
                                coursePreviewDto.discount.isActive && 
                                <div className="course-preview__main-section__right-panel__lowest-price">
                                    Najniższa cena z ostatnich 30 dni przed obniżką: {getFullPrice()} zł
                                </div>
                            }

                            <div className="course-preview__main-section__right-panel__buttons">
                                <BuyNowButton 
                                    className={handleBuyNowClass()}
                                    onClick={handleBuyNowClick}
                                />
                                {
                                    !props.isAllCoursesBundle &&
                                    <div 
                                        className="not-selectable course-preview__main-section__right-panel__buttons__button course-preview__main-section__right-panel__buttons__button--right"
                                        onClick={() => { 
                                            window.scrollTo(0, 0);

                                            handleStatsRegister(
                                                location.pathname,
                                                false,
                                                false,
                                                false,
                                                false,
                                                true,
                                            );
                                            navigate(`/lessonPreview/${courseId}`);
                                        }}
                                    >
                                        <PlayCircleIcon200/>
                                        <span>
                                            {t("kurs.preview.previewPage.watchSampleLesson")}
                                        </span>
                                    </div>
                                }
                            </div>
                            {/* <BuyAllCoursesPreviewPage coursePreviewPageDto={coursePreviewDto} currentCoursePriceAfterDiscount={getPriceAfter(coursePreviewDto.discount, getFullPrice())}/> */}
                        </MediaQuery>

                        {/* <img className="course-preview__main-section--container courseIcon" src={coursePreviewDto.courseIcon}></img> */}

                        <div className="display-on-mobile">
                            {
                                props.isAllCoursesBundle ? 
                                    <BundleSectionsWrapper
                                        activeSectionIndex={activeSectionIndex} 
                                        setActiveSectionIndex={setActiveSectionIndex}
                                        courseContent={coursePreviewDto.courseContent}
                                        description={coursePreviewDto.fullDescription}
                                        courseRecomendation={coursePreviewDto.courseRecomendation}
                                        fromLanding={false}
                                        courseName={coursePreviewDto.courseName}
                                        buyAllCoursesData={coursePreviewDto.buyAllCoursesData}
                                        handleBuyNowClick={handleBuyNowClick}
                                    />
                                :
                                    <CourseSectionsWrapper 
                                    activeSectionIndex={activeSectionIndex} 
                                    setActiveSectionIndex={setActiveSectionIndex}
                                    courseContent={coursePreviewDto.courseContent}
                                    description={coursePreviewDto.fullDescription}
                                    courseRecomendation={coursePreviewDto.courseRecomendation}
                                    publiclyAvailableChapter={coursePreviewDto.publiclyAvailableChapter}
                                    publiclyAvailableLessons={coursePreviewDto.publiclyAvailableLessons}
                                    fromLanding={false}
                                    courseName={coursePreviewDto.courseName}
                                    handleBuyNowClick={handleBuyNowClick}
                                    />
                            }
                       
                        </div>

                    </div>
                </div>
            </div>

            <div className="course-preview__footer-separator">
                <div className="course-preview__footer-separator__line"/>
            </div>
            
            {
                buyNowClicked ? "" : <Cookie/>
            }
            <div ref={footerRef}>
                <LandingFooter/>
            </div>
        </div>
    )
}