import { Block } from "../../../../../../../shared/block";
import { useTranslation } from "react-i18next";
import { Guarantee } from "../../../../MainPage/Guarantee/Guarantee";
import { Dispatch, RefObject, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../../components/auth/authContext/AuthContextProvider";
import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Authors, AuthorsProps } from "./Authors/Authors";
import { DisplayedDescriptionWrapper } from "../../DisplayedDescriptionWrapper/DisplayedDescriptionWrapper";
import { YouAreNotAlone } from "../../YouAreNotAlone/YouAreNotAlone";

interface DisplayedCourseDescriptionProps {
    description: Block[];
    courseContent: {
        chapterName: string;
        lessons: string[];
    }[]
    publiclyAvailableChapter: number;
    publiclyAvailableLessons: number[];
    setTableOfContentsRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
    setAboutAuthorsRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
    fromLanding?: boolean;
    courseName: string;
    handleBuyNowClick: () => void;
}

interface CourseTableOfContentsProps {
    courseContent: {
        chapterName: string;
        lessons: string[];
    }[]
    publiclyAvailableChapter: number;
    publiclyAvailableLessons: number[];
    setTableOfContentsRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
    fromLanding?: boolean;
}

export const CourseTableOfContents = (props: CourseTableOfContentsProps) => {
    const tableOfContentsRef = useRef<HTMLDivElement>(null);
    const { courseId } = useParams();


    

    useEffect(() => {
        props.setTableOfContentsRef(tableOfContentsRef.current);
    }, [tableOfContentsRef])

    const isActive = (chapterIndex: number, lessonIndex: number) => {
        if(chapterIndex === props.publiclyAvailableChapter) {
            if(props.publiclyAvailableLessons.includes(lessonIndex)) {
                return true;
            }
        } return false;
    }

    const navigate = useNavigate();

    const handleActiveLessonClick = () => {
        navigate(`/lessonPreview/${courseId}`)
    }

    return (
        // <div className="course-preview__main-section__left-panel__text-section__learning-method__table-of-contents">
        <div ref={tableOfContentsRef}>
            {props.courseContent.map((chapter, chapterIndex) => {
                return (
                    <div key={chapterIndex} className="course-preview__main-section__left-panel__text-section__learning-method__table-of-contents">
                        <div className="course-preview__main-section__left-panel__text-section__learning-method__table-of-contents__chapter">
                            <h2>👉</h2>
                            <h2>{(chapterIndex + 1) + " " + chapter.chapterName}</h2>
                        </div>
                        <ul>
                            {chapter.lessons.map((lesson, index) => {
                                if(isActive(chapterIndex, index)) {
                                    if(props.fromLanding) return  <li className={"active"} key={index}>{lesson } ( tu jesteś )</li>
                                    
                                    return (                                        
                                        <li onClick={handleActiveLessonClick} className={"active"} key={index}>{lesson } (darmowa lekcja - kliknij aby przejść)</li>
                                    )
                                }
                                return  <li className="inactive" key={index}>{lesson}</li>
                            })}
                        </ul>
                    </div>
                )
            })}
        </div>
    )
}

interface BulletProps {
    emoji: string;
    children: JSX.Element;
    card?: boolean;
}

export const Bullet = (props: BulletProps) => {
    return (
        <div className={`course-preview__main-section__left-panel__text-section__learning-method__text__bullet-point__emoji--container ${props.card ? "card" : ""}`}>
            <span className="course-preview__main-section__left-panel__text-section__learning-method__text__bullet-point__emoji">
                {props.emoji}
            </span>
            <span>{props.children}</span>
        </div>
    )
}

export const DisplayedCourseDescription = (props: DisplayedCourseDescriptionProps) => {
    const { t } = useTranslation();
    
    const authContext = useContext(AuthContext);


    return (
        <DisplayedDescriptionWrapper
            courseName={props.courseName}
            setTableOfContentsRef={props.setTableOfContentsRef}
            setAboutAuthorsRef={props.setAboutAuthorsRef}
            fromLanding={props.fromLanding}
            isAllCoursesBundle={false}
            handleBuyNowClick={props.handleBuyNowClick}
        >

            <div className="course-preview__main-section__left-panel__text-section__learning-method__text">
                <span>  
                    Każda lekcja to solidna dawka <b>wiedzy praktycznej</b>, 
                    której potrzebujesz do <b className="purple">zdania kolokwiów i sesji.</b> Prowadzimy za rękę przez najważniejsze zagadnienia, w przyjemnej atmosferze i w tempie, które wybierasz Ty.
                </span>
                <br/><br/>
                <h2>
                    Każda lekcja na platformie składa się z:
                </h2>
                <br/>
                    <Bullet emoji="🎥">
                        <span><b className="purple">filmu</b> z wieloma animacjami, tłumaczącego teorię oraz zadania</span>
                    </Bullet>
                    <Bullet emoji="📝">
                        <span><b className="purple">zadań otwartych</b> wraz z omówieniami</span>
                    </Bullet>
                    <Bullet emoji="🧠">
                        <span><b className="purple">zadań zamkniętych</b> w formie quizów</span>
                    </Bullet>
                    <Bullet emoji="📒">
                        <span><b className="purple">notatek</b> z ilustracjami</span>
                    </Bullet>
                <br/>

                <span>
                    Na sam koniec dostajesz jeszcze <b>interaktywny test końcowy</b> kompleksowo sprawdzający Twoją wiedzę.
                </span>

                <div className="course-preview__main-section__left-panel__text-section__learning-method__laptop">
                    <img  className="laptop" alt="" src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/laptops/laptop-test-koncowy.jpg`}></img>
                </div>

                <h2>
                    Zakup kursu nie wiąże się z żadnym ryzykiem
                </h2>
                
                <Guarantee authContext={authContext} component="course preview"/>

                <YouAreNotAlone />
                <br/>
                <h2>
                    Spis treści:
                </h2>
                <CourseTableOfContents 
                    courseContent={props.courseContent} 
                    publiclyAvailableChapter={props.publiclyAvailableChapter} 
                    publiclyAvailableLessons={props.publiclyAvailableLessons}
                    setTableOfContentsRef={props.setTableOfContentsRef}
                    fromLanding={props.fromLanding}
                />
            </div>
        </DisplayedDescriptionWrapper>
    )
}