interface PanelSelectorWrapperProps {
    children: React.ReactNode;
}

export const PanelSelectorWrapper = ({ children }: PanelSelectorWrapperProps) => {
    return (
        <div className="course-preview__main-section__left-panel__panels-selector">
            {children}
        </div>
    )
}