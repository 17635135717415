import React, { useState } from "react";
import { ReactNode } from "react";

interface GlobalDataContextProps {
    title: string;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    isMainView: boolean;
    setIsMainView: React.Dispatch<React.SetStateAction<boolean>>;
    isMainTest: boolean;
    setIsMainTest: React.Dispatch<React.SetStateAction<boolean>>;
    chapterTitle: string;
    setChapterTitle: React.Dispatch<React.SetStateAction<string>>;
    allCoursesBundleId: string;
    setAllCoursesBundleId: React.Dispatch<React.SetStateAction<string>>;
}

export const GlobalDataContext = React.createContext<GlobalDataContextProps>({} as GlobalDataContextProps);

interface GlobalDataContextProviderProps {
    children: ReactNode;
}

export const GlobalDataContextProvider = (props: GlobalDataContextProviderProps) => {
        const [title, setTitle] = useState("");
        const [isMainView, setIsMainView] = useState(false);
        const [isMainTest, setIsMainTest] = useState(false);
        const [chapterTitle, setChapterTitle] = useState("");
        const [allCoursesBundleId, setAllCoursesBundleId] = useState("");
        return (
            <GlobalDataContext.Provider value={{
                title,
                setTitle,
                isMainView,
                setIsMainView,
                chapterTitle,
                isMainTest,
                setIsMainTest,
                setChapterTitle,
                allCoursesBundleId,
                setAllCoursesBundleId
            }}>
                {props.children}
            </GlobalDataContext.Provider>
        )
}