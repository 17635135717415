import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import "./newFeatureSection.scss";
import { PlayCircleIcon400 } from "../../Icons/PlayCircleIcon400";
import { NeurologyIcon400 } from "../../Icons/NeurologyIcon400";
import { BookIcon } from "../../Icons/bookIcon";
import { ExtensionIcon400 } from "../../Icons/ExtensionIcon400";
import { Chat400Icon } from "../../Icons/Chat400";
import { CheckListIcon400 } from "../../Icons/CheckList400";
import { CarouselBackground } from "../graphics/CarouselBackground";
import { Intrests400Icon } from "../../Icons/Intrests400Icon";
import { OpenInFull400Icon } from "../../Icons/OpenInFull400Icon";
import { ToggleOn400Icon } from "../../Icons/ToggleOn400Icon";
import { EmojiObject400Icon } from "../../Icons/EmojiObject400Icon";
import { School400Icon } from "../../Icons/School400Icon";
import { MailIcon400 } from "../../Icons/MailIcon400";
import { DiscordIcon } from "../../Icons/DiscordIcon";

export const NewFeatureSection = () => {
    const { t } = useTranslation();
    
    const features = [
        { 
            name: 'Animowane lekcje', 
            icon: <PlayCircleIcon400/>, 
            imgIndex: 2, 
            headline: 'Każda lekcja jest animowana', 
            text: 'Masz dość niestarannego pisania na tablecie przez prowadzących? W Nasesje poświęcamy setki godzin, aby wszystkie materiały zostały starannie zanimowane.',
            subHeadline1: "Nagrania do lekcji",
            subIcon1: <PlayCircleIcon400/>,
            subText1: "Każda lekcja posiada w pełni wyanimowaną lekcję - bez bazgrania po tablicy.",
            subHeadline2: "Ciekawostka",
            subIcon2: <Intrests400Icon/>,
            subText2: "Niektóre lekcje składają się nawet z kilkuset elementów, które zostały ze sobą specjalnie ułożone w procesie animacji."
        },

        { 
            name: 'Notatki', 
            icon: <BookIcon/>, 
            imgIndex: 3, 
            headline: 'Notatki dołączone do lekcji', 
            text: 'Do lekcji dołączone są notatki zawierające podsumowanie materiału omówionego w trakcie nagrania. Znajdziesz w nich wszystko czego potrzeba do szybkiej powtórki.',
            subIcon1: <BookIcon/>,
            subHeadline1: "Notatki do lekcji",
            subText1: "Wzory, zdjęcia, najważniejsze zadania i kluczowe porady - wszystko to znajdziesz w notatkach.",
            subHeadline2: "Tryb pełnoekranowy",
            subIcon2: <OpenInFull400Icon/>,
            subText2: "Do wygodnego przeglądania notatek, dodaliśmy tryb pełnoekranowy w którym czytanie notatek jest jak czytanie pdf'a."
        },
        { 
            name: 'Quizy', 
            icon: <ExtensionIcon400/>, 
            imgIndex: 4, 
            headline: 'Quizy do utrwalenia wiedzy',
            text: 'Po obejrzeniu lekcji i przejrzeniu notatek, sprawdź swoją wiedzę z danego tematu przez rozwiązanie zestawu zadań zamkniętych.',
            subIcon1: <ExtensionIcon400/>,
            subHeadline1: "Quizy do lekcji",
            subText1: "Zadania zamknięte pozwolą ci utrwalić część teoretyczną lekcji.",
            subHeadline2: "Sprawdzenie poprawności",
            subIcon2: <ToggleOn400Icon/>,
            subText2: "Po rozwiązaniu quizu możesz zapisać i przeglądać swoje rozwiązania."
        },
        { 
            name: 'Zadania otwarte',
            icon: <NeurologyIcon400/>, 
            imgIndex: 1, 
            headline: 'Zadania otwarte do lekcji', 
            text: 'Poza zadaniami zamkniętymi do lekcji dołączone są również zadania otwarte, które sprawdzą twoją umiejętność rozwiązywania złożonych zadań  egzaminacyjnych.',
            subIcon1: <NeurologyIcon400/>,
            subHeadline1: "Zadania otwarte",
            subText1: "W każdej lekcji znajduje się zestaw zadań otwartych.",
            subHeadline2: "Dołączone odpowiedzi",
            subIcon2: <EmojiObject400Icon/>,
            subText2: "Do zadań otwartych dołączone są rozpisane rozwiązania z którymi możesz porównać swoje rozwiązania."
        },
        { 
            name: 'Test końcowy', 
            icon: <CheckListIcon400/>, 
            imgIndex: 5, 
            headline: 'Podsumowujący test końcowy', 
            text: 'Po obejrzeniu lekcji, rozwiązaniu zadań i quizów rozwiąż podsumowujący test końcowy z najważniejszymi zadaniami z każdego działu.',
            subIcon1: <CheckListIcon400/>,
            subHeadline1: "Test końcowy",
            subText1: "Test końcowy zawiera kilkadziesiąt przekrojowych zadań wybranych z lekcji.",
            subHeadline2: "Nowe typy zadań",
            subIcon2: <School400Icon/>,
            subText2: "Test końcowy zawiera dodatkowe typy zadań pozwalające lepiej utrwalić wzory"
        },
        { 
            name: 'Konsultacje', 
            icon: <Chat400Icon/>, 
            imgIndex: 6, 
            headline: 'Konsultacje z prowadzącym', 
            text: 'Masz pytania do lekcji? Nie umiesz poradzić sobie z zadaniem? Napisz do nas na maila lub na discordzie, a postaramy ci się pomóc.',
            subIcon1: <MailIcon400/>,
            subHeadline1: "Kontakt mailowy",
            subText1: "Jakiekolwiek pytania do kursu możesz wysłać na naszego maila: support@nasesje.com",
            subHeadline2: "Kanał Discord",
            subIcon2: <DiscordIcon/>,
            subText2: "Dołącz również do kanału na discordzie, gdzie wraz z innymi uczestnikami kursu możesz wymieniać się wiedzą. "
        },
    ]

    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div className="main-page__new-feature-section">

            <div className="main-page__intro-message">
                <div className="main-page__intro-message__text-main">
                    <span className="main-page__intro-message__text-main__main">
                        Co znajdziesz w kursach?
                    </span>

                </div>    
                <div className="main-page__intro-message__text-bottom">
                    <span>
                        NaSesje.com to więcej niż tylko nagrnania. Zobacz co dla ciebie przygotowaliśmy do każdego kursu:
                    
                    </span>
                </div>
            </div>

            <div className="main-page__new-feature-section__navigation">

                {
                    features.map((el, index) => 
                        <div 
                            key={index} 
                            className={`main-page__new-feature-section__navigation__panel main-page__new-feature-section__navigation__panel--${(index===activeIndex) ? 'active' : 'inactive'}`}
                            onClick={() => setActiveIndex(index)}
                            
                        >
                            {el.icon}
                            <span>{el.name}</span>
                        </div>
                    
                    )
                }

            </div>


            <div className="main-page__new-feature-section__data">
                
                
                <div className="main-page__new-feature-section__data__left">
                    <CarouselBackground/>
                    <img
                        className={`${(activeIndex === 5) ? 'shadowless': 'shadowed'} `}
                        loading="lazy"
                        src={`https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/platform-panels-preview/panel-0${features[activeIndex].imgIndex}.png`}
                        alt="feature"
                    />
                </div>

                <div className="main-page__new-feature-section__data__right">
                    <h2>{features[activeIndex].headline}</h2>
                    <span>{features[activeIndex].text}</span>

                    <div className="main-page__new-feature-section__data__right__features">
                        <div className="main-page__new-feature-section__data__right__features__left">
                            <div className="main-page__new-feature-section__data__right__features__left__headline">
                                {features[activeIndex].subIcon1}
                                <span>{features[activeIndex].subHeadline1}</span>
                            </div>
                            
                            

                            <span>
                                {features[activeIndex].subText1}
                            </span>
                        </div>
                        <div className="main-page__new-feature-section__data__right__features__right">
                            <div className="main-page__new-feature-section__data__right__features__right__headline">
                                {features[activeIndex].subIcon2}
                                <span>{features[activeIndex].subHeadline2}</span>
                            </div>
                            <span>
                                {features[activeIndex].subText2}
                            </span>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}