export const langPl = {
    translation: {
        supportEmail: "support@nasesje.com",
        platformName: "NaSesje.com",
        title: "NaSesje",

        code: {
            copy: "kopiuj"
        },

        alert: {
            understandButton: "Rozumiem",
            
            logout: {
                headline: "Wylogowano pomyślnie",
                message: "Zostaniesz przeniesiony do strony głównej.",
            }
        },

        error: {
            oops: "Upsssss",
            somethingWentWrong: "Wygląda na to, że coś poszło nie tak.",
            weTrackTheseTypesOfErrors: "Śledzimy tego typu błędy automatycznie, jednak gdyby pojawiał się on wielokrotnie prosimy daj nam o tym znać pod adresem email",
            backToThePage: "Wróć na stronę",
            pageYouAreLookingForDoesntExist: "Szukana przez ciebie strona nie istnieje.",
            pageYouAreLookingForDoesntExistFull: "Ups, wygląda na to, że strona, której szukasz nie istnieje. Kliknij poniższy przycisk, aby wrócić na główną stronę platformy."
        },

        languageSelector: {
            languageSettings: "Ustawienia języka",
            languageChangeInfo: "Zmiana języka spowoduje zarówno zmianę języka platformy jak i języka w jakim prowadzone są kursy.",
            chooseOneFromTheAvaiable: "Wybierz jeden z dostępnych języków",
            closeWindow: "Zamknij okno"
        },

        login: {
            loginToYourAccount: "Zaloguj się do swojego konta",
            dontHaveAnAccount: "Nie posiadasz konta",
            signUp: "Zarejestruj się",
            emailAddress: "Adres email",
            password: "Hasło",
            login: "Zaloguj",
            resetPassword: "Zresetuj hasło",
            invalidEmailOrPassword: "Nieprawidłowy adres email lub hasło.",
            forgotPassword: "Zapomniałeś hasła",
        },

        signUp: {
            signUpToTheWebsite: "Utwórz nowe konto",
            alreadyHaveAnAccount: "Posiadasz już konto",
            login: "Zaloguj się",
            signUp: "Zarejestruj się",
            confirmPassword: "Potwierdź hasło",
            acceptTermsAndConditions: "Akceptuję regulamin korzystania z serwisu NaSesje.com",
            websiteStatue: "Regulamin serwisu NaSesje.com",
            passwordsNotTheSame: "Podane hasła nie są takie same",

            error: {
                accountAlreadyExists: "Istnieje już konto o podanym adresie email.",
                internalError: "Podczas tworzenia konta wystąpił błąd, spróbuj ponownie później."
            },

            acceptTermsAndPolicy: {
                IAccept: "Akceptuję",
                statute: "regulamin",
                and: "oraz",
                privacyPolicy: "politykę prywatności",
                service: "serwisu",
                NaSesjeCom: "NaSesje.com"
            },

            passwordRequirements: {
                min8signs: "Minimum 8 znaków",
                min1capitalLetter: "Co najmniej jedna duża litera",
                min1nonCapitalLetter: "Co najmniej jedna mała litera",
                min1digit: "Co najmniej jedna cyfra"
            }

        },

        sendResetEmail: {
            emailSent: "Link do zmiany hasła został wysłany.",
            errorOccured: "Wystąpił błąd, spróbuj ponownie później.",
            ifEmailIsCorrect: "Wprowadź adres email związany z kontem."
        },

        resetPassowrd: {
            newPassword: "Nowe hasło",
            confirmNewPassword: "Potwierdź nowe hasło",
            resetYourPassword: "Zresetuj stare hasło",
            regainYourAccountAccess: "i odzyskaj dostęp do swojego konta.",
            resetPassowrd: "Zresetuj hasło",

            error: {
                linkExpired: "Link resetujący hasło jest nieprawidłowy lub już wygasł.",
                internalError: "Podczas zmiany hasła wystapił błąd, spróbuj ponownie później."
            },

            passwordChanged: "Hasło zostało poprawnie zresetowane! Zaloguj się klikając w poniższy przycisk.",
            logToYourAccount: "Zaloguj się nowym hasłem",
            tryAgain: "Spróbuj ponownie"
        },


        navbar: {
            myCourses: "Moje kursy",
            myAccount: "Moje konto",
            coursesShop: "Sklep",
            changeLanguage: "Zmień język",
            logout: "Wyloguj",
            createAccount: "Utwórz konto",
            goToPlatform: "Przejdź do platformy",
            login: "Zaloguj się"        
        },

        footer: {
            anyQuestionsAsk: "Masz jakieś pytania?",
            anyQuestionsAnswer: "Chętnie na nie odpowiemy. Napisz do nas maila, lub sprawdź naszą sekcję FAQ z najczęściej zadawanymi pytaniami.",
            sendEmail: "Wyślij maila",
            FAQSection: "Sekcja FAQ",

            bottomHeadlines: {
                moreInformations: "Więcej informacji",
                helpfullLinks: "Pomocne linki",
                contact: "Kontakt",
            },

            bottomLinks: {
                aboutUs: "O nas",
                termsOfService: "Regulamin serwisu",
                privacyPolicy: "Polityka prywatności",
                partnership: "Współpraca",
                informativeDuties: "Obowiązki informacyjne",

                myAccount: "Moje konto",
                courseShop: "Sklep z kursami",
                FAQSection: "Sekcja FAQ",
                forum: "Forum z zadaniami",
            },

            copyrightDisclaimer: {
                allRightsReserved: "Wszystkie prawa zastrzeżone"
            }
        },

        FAQ: {
            mostFrequentlyAskedQuestions: "Najczęściej zadawane pytania",
            descriptionTop: "Poniżej znajduje się opracowana przez nas sekcja, ktora powstała z zestawienia odpowiedzi na najczęściej pojawiające się pytania dotyczące działania platformy <b>NaSesje.com</b>.",
            questionsAboutThePlatform: "Pytania dotyczące platformy",
            filtered: "filtrowane",
            textNotFound: "Nie znaleziono szukanej przez ciebie frazy.",
            insertWord: "wprowadź słowo",
            reset: "Resetuj",
            descriptionBottom: "Mamy nadzieję, że zawarte w niej informacje pozwolą ci na szybkie znalezienie odpowiedzi na twoje pytania. Jeśli jednak tak by się nie stało zachęcamy do kontaktu pod adresem email: ",
            listElements: {
                el0: {
                    question: "Dla kogo skierowane są kursy?",
                    answer: "Opracowane przez nas kursy nastawione są na efektywne <b>nauczanie studentów uczelni wyższych. </b> <br/><br/> Wszystkie materiały przygotowane do kursów (<b>nagrania</b>, <b>quizy</b> oraz <b>zadania</b>)  zostały zoptymalizowane pod kątem wiedzy jaka wymagana jest do bezproblemowego zaliczenia przedmiotów prowadzonych na najlepszych polskich uczelniach."
                },
                el1: {
                    question: "Ile kosztuje kurs?",
                    answer: "<b>NaSesje.pl</b> wspiera wyrównywanie szans. Z racji tego staramy się zaproponować jak najbardziej dostępną cenę dla każdego studenta i studentki. <br/><br/>Ceny kursu z całego przedmiotu zamykają się w kwocie <b>299 złotych</b> co kosztom około dwóch/trzech lekcji korepetycji."
                },
                el2: {
                    question: "Jak długo trwa dostęp do kursu?",
                    answer: "Zakupując dany kurs otrzymujesz do niego dostęp na <b>okres 8 miesięcy od momentu dokonania zakupu.</b> Przez ten okres kurs będzie dostępny na twoim koncie a wraz z nim wszystkie testy, quizy  oraz zadania otwarte z nim związane."
                },
                el3: {
                    question: "Czy mogę otrzymać fakturę?",
                    answer: "Tak, istnieje taka możliwość. Konieczne jest jednak wysłanie do nas maila na adres: <b>adresSesja@naSesje.com</b> niezwłocznie po złożeniu zamówienia. <br/><br/>W mailu należy podać <b>numer zamówienia</b> oraz <b>dane do faktury</b>."
                },
                el4: {
                    question: "Jak można się z wami skontaktować?",
                    answer: "Aby uzyskać jak najszybszą odpowiedź na swoje pytanie wyślij do nas maila na adres: <b>support@naSesje.com</b> lub napisz do nas na <b>Facebook’u</b>. <br/><br/>Staramy się jak najszybciej udzielić odpowiedzi na wszelkie pytania, na większość z nich uzyskasz <b>odpowiedź w ciągu zaledwie 1 dnia</b>."
                },
                el5: {
                    question: "Czy powstaną nowe kursy?",
                    answer: "Nasza platforma <b>NaSesje.pl</b> nieustannie się rozwija. Ciągle opracowywujemy nowe kursy we współpracy z najlepszymi specjalistami z danych dziedzin.<br/><br/>Nie oznacza to jednak, że już istniejące kursy są porzucane - jest wręcz przeciwnie. Celem naszej platformy jest dostarczanie materiałów na najwyższym możliwym poziomie. W związku z tym cały czas ulepszamy treści dostępne na naszej platformie. "
                },
                el6: {
                    question: "Jakich dziedzin dotyczą kursy?",
                    answer: "Tworzone przez nas kursy skupiają się aktualnie na przedmiotach związanych z:  <b>szeroko pojętą matematyką akademicką</b>,  <b>obliczeniami komputerowymi</b> oraz  <b>informatyką</b>. <br/><br/>Zakres kursówów dostępnych na naszej stronie oraz tematów jakie poruszają  jest natomiast nieustannie zwiększany."
                },
                el7: {
                    question: "Czy potrzebuję coś pobierać?",
                    answer: "<b>Na szczęscie nie</b>. Nasza platforma i wszystkie dostępne w niej kursy dostępne są dla każdego z poziomu przeglądarki. <br/><br/>Jeśli tylko posiadasz internet możesz uzyskać dostęp do swojego kursu o dowolnej porze z dowolnego miejsca na Ziemi,"
                },
                el8: {
                    question: "Czy platofrma działa na telefonach?",
                    answer: "<b>Tak, wszystkie funkcje naszej platformy dostępne są na urządzeniach mobilnych</b>.  <br/><br/>Dla najlepszej jakości nauki rekomendujemy jednak wykorzystanie laptopów lub komputerów stacjonarnych ustawionych w ergonomicznym otoczeniu. Rozumiemy jednak, że czasami istnieje potrzeba dosłuchania nagrania lub rozwiązania testu w podróży."
                },
                el9: {
                    question: "Co otrzymam po zakupie kursu?",
                    answer: "Po zakupieniu kursu otrzymasz natychmiastowy dostęp do danego kursu w którego skład wchodzą: starannie przygotowane <b>nagrania do każdej lekcji</b>, <b>testy zamknięte i otwarte oraz notatki</b> po każdej lekcji, <b>odpowiedzi do zadań otwartych.</b> <br/><br/>Co więcej zyszkasz <b>dostęp do testu końcowego</b>, który stanowi podsumowanie wszystkich najważniejszych zagadnień z danego działu."
                },
            }

        },

        userPanel: {
            userPanelName: "Panel użytkownika",
            viewAvaiableCourses: "Przeglądaj dostępne kursy",
            goToTheShop: "Przejdź do sklepu",

            
            avaiableCoursesInfo: {
                youOwn: "Posiadasz",
                of: "z",
                avaiableCourses: "dostępnych kursów"
            },

            noCoursesPanel: {
                youDontHaveAnyCourses: "Nie posiadasz jeszcze żadnych kursów.",
                mathematicalAnalysis: {
                    el1: "Analiza",
                    el2: "Matematyczna"
                },
                logicAndSetTheory: {
                    el1: "Logika i teoria",
                    el2: "mnogości"
                },
                linearAlgebra: {
                    el1: "Algebra i",
                    el2: "geometria"
                },
                discreteMathematics: {
                    el1: "Matematyka",
                    el2: "Dyskretna"
                },

                bulletPoints: {
                    b1: "Dostęp do nagrań i materiałów z poziomu przeglądarki",
                    b2: "Zagadnienia omawiane w sposób pogłębiony",
                    b3: "Wiedza z całego przedmiotu w cenie 2 korepetycji"
                },

                mostOftenSelected: "Najczęściej wybierane kursy",
                findYourFirstCourse: "Znajdź swój pierwszy kurs dostosowany do twoich potrzeb",
                recordingsAccess: "W ramach każdego kursu otrzymasz dostęp do nagrań, quizów oraz zadań otwartych nastawionych na szybkie i skuteczne przyswajanie skomplikowanej wiedzy",
                searchAllCourses: "Przeglądaj wszystkie kursy",
                whyItIsWorthIt: "Dlaczego warto"

            },

            courseSubtitles: {
                myCourses: "Moje kursy",
                changeUsername: "Zmień nazwę użytkownika",
                changePassword: "Zmień hasło",
                changeEmail: "Zmień adres e-mail",
                changeNotifications: "Ustawienia powiadomień",
                closeAccount: "Zamknij konto"
            },

            courseButtons: {
                previousPage: {
                    full: "Poprzednia strona",
                    short: "Poprzednia"
                },
                nextPage: {
                    full: "Następna strona",
                    short: "Następna"
                }
            },
            
            settings: {
                changeCurrentUsername: "Zmień aktualną nazwę użytkownika",
                changeUsernameDescription: "Zmień nazwę użytkownika lub imię po którym będziemy zwracać się do ciebie w powiadomieniach oraz mailach. Domyślna nazwa użytkownika stanowi przedrostek maila użytego do utworzenia konta.",
                currentUsername: "Aktualna nazwa użytkownika",
                newUsername: "Nowa nazwa użytkownika",
                username: "nazwa użytkownika",
                usernameRequirement: "Nowa nazwa użytkownika powinna mieć",
                minimum5Signs: "minimalnie 5 znaków",
                maximum20Signs: "maksymalnie 15 znaków",
                nameAndSurnameDescription: "Imię i nazwisko będzie używane zamiennie z nazwą użytkownika.",
                currentNameAndSurname: "Aktualne imię i nazwisko",
                notSet: "nie ustawiono",
                newNameAndSurname: "Nowe imię i nazwisko",
                nameSurname: "imię nazwisko",
                confirmChanges: "Zatwierdź zmiany",
                
                changeYourPassword: "Zmień swoje hasło",
                changeYourPasswordDescription: "Po wypełnieniu formularza zmiany hasła i wysłaniu zgłoszenia otrzymasz od nas email przesłany na adres wykorzystany podczas tworzenia konta z linkiem którego kliknięcie potwierdzi zmianę hasła.",
                insertOldPassword: "Wprowadź stare hasło",
                oldPassword: "stare hasło",
                newPasswordRequirements: "Nowe hasło powinno zawierać co najmniej",
                passwordRequirements: {
                    signsCount: "8 znaków",
                    capitalLetter: "1 dużą literę",
                    smallLetter: "1 małą literę",
                    oneDigit: "1 cyfrę"
                },
                passwordAndPasswordConfirmMustMatch: "Nowe hasło i powtórzenie nowego hasła są zgodne",
                yesBig: "TAK",
                noBig: "NIE",
                badPassword: "Nieprawidłowe hasło",
                enterNewPassword: "Podaj nowe hasło",
                newPassword: "nowe hasło",
                repeatNewPassword: "Powtórz nowe hasło",
                updatePassword: "Aktualizuj hasło",

                changeEmail: "Zmień adres e-mail",
                changeEmailInitialDescription: "Zmieniając adres email do którego przypisane jest twoje konto zachowaj szczególną ostrożność. Upewnij się, że posiadasz dostęp do nowego adresu email, w przeciwnym wypadku możesz utracić możliwość zalogowania się do serwisu.",
                changeEmailDescription: "Zmiana adresu email możliwa jest raz na 30 dni.",
                currentEmail: "Aktualny adres emial",
                provideNewEmailDescription: "Podaj nowy adres email do którego chcesz przypisać swoje konto.",
                provideNewEmail: "Podaj nowy adres email",
                newAddress: "nowy adres",
                repeatNewAddress: "powtórz nowy adres",
                repeatNewEmail: "Powtórz nowy adres email",
                updateEmail: "Aktualizuj adres",

                codePanel: {
                  backToMenu: "Powrót do menu",  
                  confirmEmailChange: "Potwierdź zmianę adresu email",
                  emailHasBeenSent: "Na wskazany przez ciebie adres został wysłany email z kodem potwierdzającym zmianę emaila (kod ten jest ważny przez 15 minut).",
                  insertConfirmationCode: "Wprowadź kod potwierdzający",
                  confirmChange: "Zatwierdź zmianę",
                  confirm: "Zatwierdź",
                  cancel: "Anuluj"
                },

                notificationsSettings: "Ustawienia powiadomień",
                progressInformations: "Informacje o twoim progresie",
                discountInformations: "Informacje o zniżkach",
                newCoursesInformations: "Informacje o nowych kursach",
                courseExpirationInformations: "Powiadomienia o wygaśnięciu kursu",
                textSpacer: "Nie chcemy aby korzystanie z naszej platformy było uciążliwe, dlatego dajemy ci prostą opcję wyłączenia powiadomień marketingowych.",
                productsInformations: "Informacje o produktach",

                closeYourAccount: "Zamknij swoje konto",
                closeYourAccountOn: "Zamknij swoje konto na NazwaSerwisu.com",
                closeAccountDescription1: 'Operacja zamknięcia konta jest trwała. W wyniku jej działania dostęp do posiadanych przez ciebie aktywnych kursów zostanie utracony.',
                closeAccountDescription2: "Mamy nadzieję, że pomogliśmy ci w nauce, a jedynym powodem dla którego zamykasz swoje konto jest fakt, że udało ci się zdać sesję i nie musisz martwić się ju studiami.",
                confirmAccountClosing: "Czy na pewno chcesz zamknąć swoje konto?",
                satisfiedExpectationsDescription: "Czy kursy spełniły twoje oczekiwania?",
                tellUsWhatWeCanImprove: "Napisz nam co możemy poprawić.",
                yourOpinion: "Twoja opinia",
                closeAccount: "Zamknij konto",
                yes: "Tak",
                no: "Nie",
                accountDeletedHeadline: "Usunięto konto",
                accountDeletedMessage: "Twoje konto zostało poprawnie usunięte.",

                userNotification: {
                    goodNotification: "Poprawnie zaktualizowano dane",
                    badNotification: "Dane nie mogły zostać zaktualizowane"
                }
            },


        },

        kurs: {
            kurs: "Kurs",
            demonstrationLesson: {
                previewLesson: "Lekcja pokazowa",
                of: "z",
                avaiableLessons: "lekcji dostępnych  w kursie."
            },


            testKoncowy: {
                 rozwiazanieDoZadania: "Rozwiązanie do zadania",
                 rozwiazanieDoTestu: "Rozwiązanie do testu z przedmiotu",
                 rozwiazTestKoncowy: "Rozwiąż test końcowy",

                previewPage: {
                    finalTest: "test końcowy",
                    openAssignments: "zadania otwarte",
                    closedAssignments: "zadania zamknięte",
                    fillGaps: "uzupełnij luki",

                    redoYourTests: "Rozwiązuj test do woli.",

                    completing: {
                        after: "Po",
                        completing: "ukończeniu",
                        finalTest: "testu końcowego możesz rozwiązać go ponownie klikając poniższy przycisk."
                    },

                    redoTest: "Rozwiąż ponownie",
                    yourTestScore: "Twój wynik z testu",
                    yourScore: "Uzyskane przez ciebie wyniki z poszczególnych typów zadań są na bieżąco aktualizowane tak jak i stopień ukończenia testu.",

                    warning: {
                        warning: "Uwaga",
                        buttonClick: "kliknięcie przycisku",
                        redoTest: "rozwiąż ponownie",
                        willCauseResultDelete: "spowoduje usunięcie dotychczasowych wyników z testu końcowego."
                    },

                    solveTest: "Rozwiąż test",
                    watchSolution: "Oglądnij rozwiązanie",

                }
            },

            nawigacja: {
                backToAllCourses: "Powrót do wszystkich kursów",
                backToCourseMenu: "Powrót do panelu kursu",
                rozwiazTest: "Rozwiąż szybki test",
                rozwiazZadania: "Rozwiąż zadania otwarte",
                przegladnijNotatki: "Przeglądnij notatki",
                materialyDoLekcji: "Materiały do lekcji",
                szybkiDostep: "Szybki dostęp",
                sklepZKursami: "Sklep z kursami",
                mojeKonto: "Moje konto",
                mojeKursy: "Moje kursy",
                backToCoursePanel: "Powrót do strony kursu",
                maszJakiesPytania: "Masz jakieś pytania?",
                chetnieNaNieOdpowiemy: "Chętnie na nie odpowiemy. Napisz do nas maila, lub sprawdź naszą sekcję FAQ z najczęściej zadawanymi pytaniami.",
                wyslijMaila: "Wyślij maila",
                sekcjaFaq: "Sekcja FAQ",
                backToTheCourse: "Powrót do nagrania"

            },

            mainNavigation: {
                selectNotesFromTheLesson: "Wybierz notatki z lekcji",
                selectAssignmentsFromTheLesson: "Wybierz zadania z lekcji",
                selectQuizesFromTheLesson: "Wybierz testy z lekcji",
                downloadAllNotes: "Pobierz wszystkie notatki",
                downloadPDFwithNotes: "Pobierz plik PDF z notatkami"
            },

            video: {
                shortLessonDescription: "Krótki opis lekcji:"
            },

            zadaniaOtwarte: {
                zobaczRozwiazanie: "Zobacz rozwiązanie",
                watchAssignmentVideo: "Obejrzyj omówienie zadania",
                poprzednie: "Poprzednie",
                nastepne: "Następne",
                zadanie: "Zadanie",
                rozwiazanie: "Rozwiązanie",
                potrzebujeszDokladniejszegoWytlumaczenia: "Potrzebujesz dokładniejszego wytłumaczenia zadania?",
                mamyCosSpecjalnieDlaCiebie: "Mamy coś specjalnie dla ciebie.",
                uzyskajDostep: "Uzyskaj dostęp do nagrań w których szczegółowo, krok po kroku omawiamy każde zadanie otwarte z całego kursu.",
                dodatkowoOtrzymasz: "Dodatkowo otrzymasz nagranie z dokładnym omówieniem testu końcowego, który przygotowaliśmy dla ciebie na koniec kursu.",
                odblokujDlugie: "Odblokuj omówienie wszystkich zadań otwartych w kursie.",
                odblokujKrotkie: "Odblokuj nagrania",
                zadaniaOtwarte: "Zadania otwarte"
            },

            assignmentOffer: {
                doYouNeedBetterExplanation: "Potrzebujesz dokładniejszego wytłumaczenia zadania?",
                weHaveASpecialOfferForYou: "Mamy coś specjalnie dla ciebie.",

                desktop: {
                    el1: "46 nagrań tłumaczących zadania otwarte z całego kursu.",
                    el2: "Zadania omawiane są w bardziej szczegółowy sposób niż w dostępnych odpowiedzach.",
                    el3: "Tłumaczymy ponownie elementarne pojęcia konieczne do rozwiązania konkretnego zadania.",
                    el4: "Dodatkowo otrzymasz nagranie z dokładnym omówieniem testu końcowego, który przygotowaliśmy dla ciebie na koniec kursu.",
                },
                mobile: {
                    el1: "46 nagrań tłumaczących zadania otwarte z całego kursu.",
                    el2: "Dokładne omówienie zadań otwartych.",
                    el3: "Wytłumaczenie niezbędnych pojęć do rozwiązania zadania.",
                    el4: "Dodatkowo otrzymasz nagranie z dokładnym omówieniem testu kończącego kurs."
                },
            },

            assignmentVideo: {
                solutionToTheOpenTask: "Rozwiązanie do zadania otwartego",
                openTask: "Zadanie otwarte"
            },


            notatki: {
                przegladajNotatkiZLekcjiNaStronie: "Przeglądaj notatki z lekcji na stronie",
                downloadNotesInfo: "Pobierz notatki w postaci pliku PDF",
                downloadPDF: "Pobierz PDF",
                documentIncludingCombinedNotes: "Dokument zawierający połączone notatki ze wszystkich lekcji danego kursu możesz pobrać z poziomu panelu kursu",
                materialsToDownload: "Materiały do pobrania",
                goToCourse: "przejdź do kursu",
                notatki: "Notatki",
            },

            quiz: {
                pytanie: "Pytanie",
                test: "Test",
                wybierzPoprawnaOdpowiedz: "Wybierz prawdziwą odpowiedź:",
                uzyjStrzalekAbyWybrazOdpowiedz: "Użyj strzałek aby wybrać poprawną odpowiedź, następnie zatwierdź wybór klikając w panel odpowiedzi.",
                odpowiedzialesDobrzeNa: "Odpowiedziałeś dobrze na ",
                z: "z",
                mozliwychPytan: "możliwych pytań.",
                showAllAnswers: "Pokazuj wszystkie odpowiedzi",
                showOnlyWrongAnswers: "Pokazuj wyłącznie błędne odpowiedzi",
            
                checkYourMistakes: "Sprawdź swoje błędy przeglądając poniższe zestawienie udzielonych przez ciebie odpowiedzi, lub rozwiąż ponownie test klikając",
                here: "tutaj",
                yourAnswer: "Poprawna odpowiedź",
                correctAnswer: "Twoja odpowiedź",

                previous: "Poprzednie",
                next: "Następne",
                repeatQuiz: "Rozwiąż ponownie"
            },

            preview: {
                recordings: "nagrań",
                goToCourse: "Przejdź do kursu",
                lessons: "lekcji",
                completed: "Ukończono", 
                courseValidUntil: "Kurs aktywny do",

                goToShopPanel: {
                    browse: "Przeglądaj",
                    newCourses: "Nowe Kursy",
                    coursesSelectedForYou: "Kursy wybrane dla ciebie",
                    
                    selectedCourseNames: {
                        mathematicalAnalysis: "Analiza matematyczna",
                        algebraAndGeometry: "Algebra i geometria",
                        formalLanguagesTheory: "Teoria języków formalnych",
                    },

                    getToTheNextLevel: "Wejdź na wyższy poziom",
                    completeYourKnowledge: "Uzupełnij swoją wiedzę o brakujące przedmioty i nie martw się studiami",
                    seeTheFullOffer: "Poznaj pełną ofertę"
                },

                previewPage: {
                    courseDescription: "Opis kursu",
                    bundleDescription: "Opis paczki",
                    contents: "Spis treści",
                    forWho: "Dla kogo",

                    bulletPoints: {
                        first: "Nagrania tłumaczącego dany temat przez teorię i praktyczne przykłady.",
                        second: "Interaktywnego Quizu sprawdzającego zrozumienie omówionych pojęć.",
                        third: "Zadań otwartych (na zróżnicowanym poziomie) wraz z ich omówieniami.",
                        fourth: "Notatek zawierających najważniejsze informacje z danej lekcji."
                    },

                    howIsOurCourseCarriedOut: {
                        title: "Jak przebiega nasz kurs?",
                        ourGoalIs: "Celem zespołu",
                        nasesje: "NaSesje.com",
                        isToDefineNewQuality: "jest zdefiniowanie na nowo jakości w nauczaniu przedmiotów akademickich. Aby tego dokonać przygotowaliśmy",
                        dedicatedLearningPlatform: "dedykowaną platformę edukacyjną",
                        funAndInterestingToLearn: ", której zadaniem jest sprawienie, że nauka przedmiotów będzie się szybka i ciekawa."
                    },
                
                    prosText: {
                        moreThan: "Ponad",
                        hoursOfRecordings: "godzin nagrań",
                        interactiveTests: "Interaktywne testy po każdej lekcji.",
                        openAssignmentsBundles: "Zestawy zadań otwartych z rozpisanymi odpowiedziami.",
                        notesAccessAfterEachLesson: "Dostęp do notatek pod koniec każdej lekcji.",
                        interactiveFinalTest: "Przekrojowy, interaktywny test końcowy.",
                        materialsAccess: "Dostęp do materiałów przez 2 semestry od momentu zakupu."
                    },

                    courseProperties: {
                        lessons: "lekcji",
                        hoursOfRecordings: "h nagrań",
                        hoursOfRecordingsShort: "nagrań",
                        quizes: "quizów",
                        assignments: "zadań",
                        coursesPlural: "kursów",
                        coursesSingular: "kurs",

                        lessonsWhenNotAvailable: "lekcje",
                        hoursOfRecordingsWhenNotAvailable: "nagrania",
                        quizesWhenNotAvailable: "quizy",
                        assignmentsWhenNotAvailable: "zadania"
                    },
                    
                    everyLessonConsistsOf: "Każda lekcja na platformie składa się z:",

                    afterFinishing: "Po ukończeniu wszystkich rozdziałów kursu możesz następnie rozwiązać ",
                    interactiveFinalTest: "interaktywny test końcowy",
                    testingYourKnowledge: " sprawdzający twoją wiedzę z całego kursu.",

                    tuneYourExperience: "Dostosuj swoje doświadczenie.",
                    
                    experienceText: {
                        withinTheCourse: "W ramach kursu możesz odblokować dostęp do",
                        additionalRecordings: "dodatkowych nagrań",
                        withPreciseExplanationOf: "z dokładnym omówieniem rozwiązań zadań otwartych pojawiających się po każdej lekcji oraz testu końcowego.",
                    },

                    basePacket: "Pakiet podstawowy",
                    currencySymbol: "zł",
                    additionalRecordings: "Dodatkowe nagrania",
                    chooseOneFromTheOptionsAbove: 'Wybierz jedną z powyższych opcji, aby odblokować przycisk "kup teraz".',
                    whatWillYouFindInOurCourse: "Co znajdziesz w kursie?",
                    whatWillYouFindInOurAllCoursesBundle: "Co znajdziesz w paczce?",

                    buyNow: "Kup teraz",
                    watchSampleLesson: "Oglądnij przykładową lekcję",
                    backToCoursePreview: "Powrót do podglądu kursu"
                
                }
            },

            glowna: {
                zadaniaOtwarte: "Zadania otwarte",
                zbiorTestow: "Quizy",
                selectedChapter: "wybrany dział",
                selectedLesson: "wybrana lekcja",
                nagranieWprowadzajace: "Nagranie wprowadzające",
                lekcji: "lekcji",
                godzinNagran: "godzin nagrań",
                testow: "quizów",
                zadanOtwartych: "zadań otwartych",
                krotkiOpisKursu: "Krótki opis kursu",
                quizes: "Quizy",
                tasks: "Zadania",
                notes: "Notaki",
                
                // materialyDodatkoweDoKursu: "Materiały dodatkowe do kursu",
                // utrwalZdobytaWiedze: "Utrwal zdobytą wiedzę rozwiązując zbiór testów zamkniętych z kluczowymi zagadnieniami z całego kursu.",
                // zbiorTestow: "Zbiór testów",
                // przygotowujeszSieDoSesji: "Przygotowujesz się do sesji? Rozwiąż zadania otwarte z całego kursu i sprawdź swoje odpowiedzi.",
                // zestawyZadan: "Zestawy zadań",
                // przegladajLubPobierzZestawienieNotatek: "Przeglądaj lub pobierz zestawienie notatek z każdej lekcji kursu.",

                notatki: {
                    notatki: "Notatki",
                    przegladajNotatkiZNazwaLekcji: "Przeglądaj notatki z lekcji: ",
                    materialyDoPobrania: "Materiały do pobrania",
                    
                    downloadLessonNotes: {
                        short: "Pobierz notatki w postaci pliku PDF",
                        long: {
                            p1: "Pobierz notatki",
                            p2: "w postaci pliku PDF."
                        },
                    },

                    downloadChapterNotes: {
                        short: "Pobierz notatki z całego działu w postaci pliku PDF.",
                        long: {
                            p1: "Pobierz notatki z całego działu",
                            p2: "w postaci pliku PDF."
                        }
                    },

                    pobierzPdf: "Pobierz PDF",
                    poprzedniaNotatka: "Poprzednia notatka",
                    nastepnaNotatka: "Następna notatka"
                },
                rozdzial: {
                    godz: "godz",
                    min: "min"
                }
            }
        },

        landingNavbar: {
            login: "Zaloguj się",
            logout: "Wyloguj",
            createAccount: "Utwórz konto",
            goToPlatform: "Przejdź do platformy"
        },

        landing: {
            bulletPoints: {
                innovativeAndEfficient: "Nowoczesne i wydajne podejście do nauczania",
                coursesPrepByExperts: "Kursy opracowane przez pasjonatów nauki",
                mathOnAcademicLevel: "Matematyka, fizyka, statystyka na studia ",
                specializedEnginneringSubjects: "Specjalistyczne przedmioty inżynierskie"
            },

            withOurPlatform: "Z naszą platformą",
            fearBeforeSession: "stres przed sesją to przeszłość",
            createFreeAccount: "Załóż darmowe konto",
            goToPlatform: "Przejdź do platformy",
            seeHowWeOperate: "Zobacz jak działamy",

            features: {
                highQuality: "Wysoka jakość nagranych materiałów dostępnych online.",
                lessonsDivided: "Lekcje podzielone na rozbudowane działy tematyczne.",
                simpleExplanation: "Proste wytłumaczenie skomplikowanych pojęć i zadań.",
                wellStructuredKnowledge: "Dobrze uporządkowana wiedza, do której można wrócić.",
                youCanLearnFromEveryPlace: "Możliwość nauki z każdego miejsca z internetem.",

                headings: {
                    quality: "Jakość",
                    value: "Kompletność",
                    simplicity: "Prostota",
                    organization: "Organizacja",
                    avaiability: "Dostępność"

                }
            },



            carousel: {
                interactiveTests: "Interaktywne testy sprawdzające zrozumienie przerobionego materiału.",
                exercisesSets: "Zestawy zadań otwartych ze szczegółowo opracowanymi odpowiedzami.",
                importantNotes: "Notatki zawierające najważniejsze informacje z danej lekcji.",
                movingPlatform: "Dostęp do wszystkich kursów na telefonie i komputerze",
                courseDividedIntoChapter: "Kurs z animowanymi nagraniami do każdej lekcji."
            },

            forSession: "Nasesje",
            muchMoreThan: "więcej niż",
            onlyRecordings: "tylko nagrania",
            meetingTheExpectations: "Wychodząc naprzeciw oczekiwaniom naszej społeczności wprowadzamy nową jakość w sposobie nauczania.",

            whatsMore: "Co więcej. Dla osób, które po ukończeniu kursu chcą sprawdzić swoje umiejętności udostępniamy",
            crossSectionalTest: "przekrojowy test z całego materiału",
            withPreciseExplanation: "wraz z jego szczegółowym omówieniem w postaci nagrania.",

            reviews: {
                el01: {
                    name: "Antek K.",
                    subject: "Analiza Matematyczna",
                    review1: "Id ultricies in dignissim vitae imperdiet ut. Bibendum tincidunt purus malesuada sed suspendisse malesuada nulla. Et quis vestibulum fermentum egestas quis egestas ipsum.",
                    review2: "Quam sodales ipsum egestas at non molestie. Ornare accumsan diam integer dictum. Mattis a eget sed vulputate."
                },
                el02: {
                    name: "Karol M.",
                    subject: "Podstawy Informatyki",
                    review1: "Id ultricies in dignissim vitae imperdiet ut. Bibendum tincidunt purus malesuada sed suspendisse malesuada nulla. Et quis vestibulum fermentum egestas quis egestas ipsum.",
                    review2: "Quam sodales ipsum egestas at non molestie. Ornare accumsan diam integer dictum. Mattis a eget sed vulputate."
                },
                el03: {
                    name: "Agnieszka L.",
                    subject: "Analiza matematyczna",
                    review1: "Id ultricies in dignissim vitae imperdiet ut. Bibendum tincidunt purus malesuada sed suspendisse malesuada nulla. Et quis vestibulum fermentum egestas quis egestas ipsum.",
                    review2: "Quam sodales ipsum egestas at non molestie. Ornare accumsan diam integer dictum. Mattis a eget sed vulputate."
                },
                el04: {
                    name: "Kuba K.",
                    subject: "Logika i teoria mnogości",
                    review1: "Id ultricies in dignissim vitae imperdiet ut. Bibendum tincidunt purus malesuada sed suspendisse malesuada nulla. Et quis vestibulum fermentum egestas quis egestas ipsum.",
                    review2: "Quam sodales ipsum egestas at non molestie. Ornare accumsan diam integer dictum. Mattis a eget sed vulputate."
                },
                el05: {
                    name: "Fabian W.",
                    subject: "Podstawy Informatyki",
                    review1: "Id ultricies in dignissim vitae imperdiet ut. Bibendum tincidunt purus malesuada sed suspendisse malesuada nulla. Et quis vestibulum fermentum egestas quis egestas ipsum.",
                    review2: "Quam sodales ipsum egestas at non molestie. Ornare accumsan diam integer dictum. Mattis a eget sed vulputate."
                },
                el06: {
                    name: "Wojtek S.",
                    subject: "Analiza matematyczna",
                    review1: "Id ultricies in dignissim vitae imperdiet ut. Bibendum tincidunt purus malesuada sed suspendisse malesuada nulla. Et quis vestibulum fermentum egestas quis egestas ipsum.",
                    review2: "Quam sodales ipsum egestas at non molestie. Ornare accumsan diam integer dictum. Mattis a eget sed vulputate."
                },
                el07: {
                    name: "Ania M.",
                    subject: "Logika i teoria mnogości", 
                    review1: "Id ultricies in dignissim vitae imperdiet ut. Bibendum tincidunt purus malesuada sed suspendisse malesuada nulla. Et quis vestibulum fermentum egestas quis egestas ipsum.",
                    review2: "Quam sodales ipsum egestas at non molestie. Ornare accumsan diam integer dictum. Mattis a eget sed vulputate."
                },
                el08: {
                    name: "Kuba Z.",
                    subject: "Podstawy Informatyki",
                    review1: "Id ultricies in dignissim vitae imperdiet ut. Bibendum tincidunt purus malesuada sed suspendisse malesuada nulla. Et quis vestibulum fermentum egestas quis egestas ipsum.",
                    review2: "Quam sodales ipsum egestas at non molestie. Ornare accumsan diam integer dictum. Mattis a eget sed vulputate."
                },
                el09: {
                    name: "Mikołaj S.",
                    subject: "Logika i teoria mnogości",
                    review1: "Id ultricies in dignissim vitae imperdiet ut. Bibendum tincidunt purus malesuada sed suspendisse malesuada nulla. Et quis vestibulum fermentum egestas quis egestas ipsum.",
                    review2: "Quam sodales ipsum egestas at non molestie. Ornare accumsan diam integer dictum. Mattis a eget sed vulputate."
                },
                el10: {
                    name: "Artur W.",
                    subject: "Analiza matematyczna",
                    review1: "Id ultricies in dignissim vitae imperdiet ut. Bibendum tincidunt purus malesuada sed suspendisse malesuada nulla. Et quis vestibulum fermentum egestas quis egestas ipsum.",
                    review2: "Quam sodales ipsum egestas at non molestie. Ornare accumsan diam integer dictum. Mattis a eget sed vulputate."
                },
                el11: {
                    name: "Damian B.",
                    subject: "Podstawy Informatyki",
                    review1: "Id ultricies in dignissim vitae imperdiet ut. Bibendum tincidunt purus malesuada sed suspendisse malesuada nulla. Et quis vestibulum fermentum egestas quis egestas ipsum.",
                    review2: "Quam sodales ipsum egestas at non molestie. Ornare accumsan diam integer dictum. Mattis a eget sed vulputate."
                },
                el12: {
                    name: "Michał Z.", 
                    subject: "Logika i teoria mnogości", 
                    review1: "Id ultricies in dignissim vitae imperdiet ut. Bibendum tincidunt purus malesuada sed suspendisse malesuada nulla. Et quis vestibulum fermentum egestas quis egestas ipsum.",
                    review2: "Quam sodales ipsum egestas at non molestie. Ornare accumsan diam integer dictum. Mattis a eget sed vulputate."
                }
            },

            studentsOpinions: "Opinie studentów",
            seeWhatOtherStudentsSay: "Zobacz co mówią o nas nasi kursanci.",

            byJoiningOurPlatform: "Dołączając do naszej platfromy zyskujesz całą wiedzę z danego przedmiotu",
            inOnePlace: "w jednym miejscu",
            byLearningAGivenTopic: "Ucząc się danego przedmiotu niejednokrotnie trzeba wybierać między długim szukaniem odpowiedzi w internecie (które bardzo często są niezrozumiałe lub błędne), a drogimi korepetycjami, gdzie to my musimy dostosować się do terminów.",
            byUsingOurCourses1: "Korzystając z naszych kursów cała wiedza z danego przedmiotu znajduje się w jednym miejscu, tłumaczona jest w prosty sposób, a co naważniejsze została ona",
            preciselyChecked: "dokładnie sprawdzona",
            byUsingOurCourses2: "pod kątem wszelkich niespójności.",
            goToShop: "Przejdź do sklepu",

            avaiable: "Dostępne",
            courses: "Kursy",
            everyCourseContains: "W skład każdego kursu wchodzą: nagrania, interaktywne testy, notatki oraz zadania wraz z ich opracowaniem.",

            andNowTheBest: "A teraz najlepsze",
            hoursOfRecordings: "dziesiątki godzin nagrań, testy utrwalające wiedzę, zadania z rozwiązaniami oraz materiały do nauki dostępne są",
            inPriceOfOnly3Hours: "w cenie godziny korepetycji",
            findYourFirstCourse: "Znajdź swój pierwszy kurs",

            coursePreview: "Podgląd kursu",
            goToCourse: "Przejdź do kursu",
            lastCourseUpdate: "Ostatnia aktualizacja kursu",
            availableSoon: "Dostępny wkrótce!",
        },

        shop: {
            courses: "Kursy",
            forStudies: "na studia",
            filters: "Filtry",
            resetFilters: "Resetuj filtry",
            typesOfMaterials: "Rodzaje materiałów",

            materials: {
                separateCourses: "Pojedyncze kursy",
                courseBundles: "Paczki kursów",
                extraMaterials: "Materiały dodatkowe",
                allOffers: "Wszystke oferty"
            },

            of: "z",
            previousPage: "Poprzednia strona",
            previous: "Poprzednia",
            nextPage: "Następna strona",
            next: "Następna"
        },

        checkout: {
            incorrectEmailAddress: "Nieprawidłowy adres email",
            invalidEmailOrPassword: "Nieprawidłowy adres email lub hasło",
            accountCreatedServerErrorOccured: "Konto zostało pomyślnie utworzone. Niestety wystąpił błąd podczas przygotowywania płatności. Spróbuj ponownie później.",
            loggedInServerErrorOccured: "Zalogowano pomyślnie. Niestety wystąpił błąd podczas przygotowywania płatności. Spróbuj ponownie później.",
            

            warning: "Uwaga",
            understood: "Rozumiem",
            payment: "Płatność",
            IdontHaveAccountLong: "Nie posiadam konta",
            IdontHaveAccountShort: "Nie mam konta",
            IAlreadyHaveAccount: "Mam już konto",
            welcomeBack: "Witamy z powrotem!",
            logInToYourAccount: "Zaloguj się do swojego konta uzupełniając poniższe dane logowania, aby sfinalizować zakup.",

            notifyMe: "Powiadom mnie",
            courseIsBeingCreated: "Kurs jest w trakcie produkcji ⚙️",
            giveMail: "Podaj swój email a powiadomimy cię jak tylko będzie gotowy.",
            
            emailAddress: "Adres email",
            password: "Hasło",
            confirmPassword: "Potwierdź hasło",

            goToTheLastStep: "Przejdź do ostatniego kroku",

            stripeSection: {
                errors: {
                    unexpectedError: "Wystąpił niespodziewany błąd.",
                    paymentFailed: "Twoja płatność nie powiodła się. Spróbuj ponownie później.",

                },

                paymentSuccesfull: "Płatność zakończona sukcesem!",
                paymentProcessing: "Twoja płatność jest przetwarzana.",

                choosePrefferedPaymentType: "Wbierz preferowaną formę płatności i sfinalizuj transakcję.",
                payForThePurchase: "Opłać zakup"
            }
        }

    }
}