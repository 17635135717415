import { Dispatch, SetStateAction, useRef, useEffect, useState } from "react";
import { BundleContent } from "../../BundleSectionsWrapper";
import "./bundleTableOfContents.scss";

interface BundleTableOfContentsProps {
    bundleContent: BundleContent[];
    setTableOfContentsRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
    fromLanding?: boolean;
}

export const BundleTableOfContents = (props: BundleTableOfContentsProps) => {
    const tableOfContentsRef = useRef<HTMLDivElement>(null);
    const [expandedCourse, setExpandedCourse] = useState<number | null>(null);
    const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

    useEffect(() => {
        props.setTableOfContentsRef(tableOfContentsRef.current);
    }, [tableOfContentsRef]);

    useEffect(() => {
        props.bundleContent.forEach((_, index) => {
            const contentElement = contentRefs.current[index];
            if (contentElement) {
                if (expandedCourse === index) {
                    const height = contentElement.scrollHeight;
                    contentElement.style.height = `${height}px`;
                } else {
                    contentElement.style.height = '0px';
                }
            }
        });
    }, [expandedCourse, props.bundleContent]);

    return (
        <div ref={tableOfContentsRef} className="main-page__bundle-table-of-contents">
            {
                props.bundleContent.map((el, index) => {
                    const isExpanded = expandedCourse === index;
                    return (
                        <div key={index} className="main-page__bundle-table-of-contents__course-card"
                            onClick={() => setExpandedCourse(isExpanded ? null : index)}
                        >
                            <div className="main-page__bundle-table-of-contents__course-card__main-container">
                                <div className="main-page__bundle-table-of-contents__course-card__image">
                                    <img src={el.courseImage} alt={el.courseName} />
                                </div>
                                <div className="main-page__bundle-table-of-contents__course-card__content">
                                    <h3>{el.courseName}</h3>
                                </div>
                                <button 
                                    className={`main-page__bundle-table-of-contents__course-card__expand-btn ${isExpanded ? 'expanded' : ''}`}
                                    onClick={() => setExpandedCourse(isExpanded ? null : index)}
                                >
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6 9L12 15L18 9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div 
                                ref={el => contentRefs.current[index] = el}
                                className={`main-page__bundle-table-of-contents__course-card__chapters ${isExpanded ? 'expanded' : 'hidden'}`}
                            >
                                <div className="content">
                                    {
                                        el.chapters?.map((chapter, chapterIndex) => (
                                        <div key={chapterIndex} className="course-preview__main-section__left-panel__text-section__learning-method__table-of-contents">
                                            <div className="course-preview__main-section__left-panel__text-section__learning-method__table-of-contents__chapter">
                                                <h2>👉</h2>
                                                <h2>{(chapterIndex + 1) + " " + chapter.chapterName}</h2>
                                            </div>
                                            <ul>
                                                {chapter.lessons.map((lesson, index) => {
                                                    return  <li className="inactive" key={index}>{lesson}</li>
                                                })}
                                            </ul>
                                        </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}