interface BulletPointProps {
    icon: string;
    children: React.ReactNode;
}

export const BulletPoint = ({ icon, children }: BulletPointProps) => {
    return (
        <div className="bullet-point">
            <span className="bullet-point__icon">{icon}</span>
            <span className="bullet-point__text">
                {children}
            </span>
        </div>
    );
}; 