import { useTranslation } from "react-i18next"; 

interface BuyNowButtonProps {
    className: string;
    onClick: () => void;
}

export const BuyNowButton = ({ className, onClick }: BuyNowButtonProps) => {
    const { t } = useTranslation();

    return (
        <div className={className} onClick={onClick}>
            <span>
                {t("kurs.preview.previewPage.buyNow")}
            </span>
        </div>
    );
} 