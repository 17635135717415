import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import "./mainPage.scss";

import { DownLeftRay } from "./graphics/DownLeftRay";
import { CheckMark } from "./graphics/CheckMark";
import { Play } from "./graphics/Play";
import { ArrowGraphic } from "./graphics/ArrowGraphic";


import { OfferBoxes } from "./OfferBoxes/OfferBoxes";
import { Carousel } from "./Carousel/Carousel";
import { Opinions } from "./Opinions/Opinions";
import { Features } from "./Features/Features";
import { CourseSellSection } from "./CourseSellSection/CourseSellSection";

import { LandingFooter } from "../LandingFooter/LandingFooter";
import { LandingNavbar } from "../LandingNavbar/LandingNavbar";
import { AuthContext } from "../../components/auth/authContext/AuthContextProvider";
import { VideoPreview } from "./VideoPreview/VideoPreview";

import { TeamSection } from "./TeamSection/TeamSection";
import { FinalTestPreview } from "./FinalTestPreview/FinalTestPreview";
import { SellBaner } from "./SellBaner/SellBaner";
import { MessengerPanel } from "./Messanger/Messenger";
import { Cookie } from "./Cookie/Cookie";
import { usePageTracking } from "../../hooks/analytics/usePageTracking";
import MediaQuery from "react-responsive";
import { ArrowForwardIos400 } from "../Icons/ArrowForwardIos400";
import { LaptopSection } from "./LaptopSection/LaptopSection";
import { Guarantee } from "./Guarantee/Guarantee";
import { NewOpinions } from "./NewOpinions/NewOpinions";
import { ConsultationsPanel } from "./ConsultationsPanel/ConsultationsPanel";
import { BlogInfomertial } from "./BlogInfomertial/BlogInfomertial";
import { DiscordInvitation } from "./DiscordInvitation/DiscordInvitation";
import { NewDesktopIntroduction } from "./NewDesktopIntroduction/NewDesktopIntroduction";
import { DesktopOpinions } from "./DesktopOpinions/DesktopOpinions";
import { NewFeatureSection } from "./NewFeatureSection/NewFeatureSection";
import { CoursePackage } from "./CoursePackage/CoursePackage";
import { GlobalDataContext } from "../../components/global/globalDataContext/GlobalDataContextProvider";






export const MainPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const authContext = useContext(AuthContext);

    const ref = useRef<HTMLDivElement | null>(null);

    const handleScrollToClick = () => {
        if(ref.current !== null) {
            ref.current.scrollIntoView({ 
                behavior: 'smooth',
            });
        }
    };

    usePageTracking("landing");

    return (
        <div className="main-page">

            <LandingNavbar/>

            <SellBaner/>

            <MediaQuery maxWidth={950}>
                <LaptopSection/>
                <Guarantee authContext={authContext} component="main page"/>
            </MediaQuery>

            
            <NewDesktopIntroduction scrollToRef={handleScrollToClick} />

            <div className="main-page__introduction">
                <DownLeftRay/>
                    <img 
                        className="arena"
                        alt="" src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/arena.svg"
                    />
                <div className="main-page__introduction__left-section">
                    <div className="main-page__introduction__left-section__headline">
                        <span className="main-page__introduction__left-section__headline__text-1 desktop">
                            {t("landing.withOurPlatform")}&nbsp;
                        </span>
                        <span className="main-page__introduction__left-section__headline__text-1 mobile">
                            Dlaczego my?
                        </span>
                        <span className="main-page__introduction__left-section__headline__text-2 desktop">
                            {t("landing.fearBeforeSession")}
                        </span>
                    </div>

                    <div className="main-page__introduction__left-section__bullet-points">

                        <div className="main-page__introduction__left-section__bullet-points__bullet-point">
                            <div>

                                <span className="material-symbols-outlined">
                                    🚀
                                    {/* rocket */}
                                </span>
                            </div>
                            <div>
                                <span>Pomożemy Ci zdać sesję na studiach</span>
                            </div>
                        </div>
                        <div className="main-page__introduction__left-section__bullet-points__bullet-point">
                            <div>
                                <span className="material-symbols-outlined">
                                    🧑‍💻
                                    {/* school */}
                                </span>
                            </div>
                            <div>
                                <span>{t("landing.bulletPoints.coursesPrepByExperts")}</span>  
                            </div>
                        </div>
                        <div className="main-page__introduction__left-section__bullet-points__bullet-point">
                            <div>
                                <span className="material-symbols-outlined">
                                    {/* function */}
                                    📚
                                </span>
                            </div>
                            <div>
                                <span><b>Matematyka</b>, <b>Fizyka</b>, <b>Statystyka</b>, na studia</span>  
                            </div>
                        </div>

                        <div className="main-page__introduction__left-section__bullet-points__bullet-point">
                            <div>
                                <span className="material-symbols-outlined">
                                    📱
                                </span>
                            </div>
                            <div>
                                <span>Ponad <b>8</b> milionów wyświetleń na wszystkich platformach</span>  
                            </div>
                        </div>
                        <div className="main-page__introduction__left-section__bullet-points__bullet-point">
                            <div>
                                <span className="material-symbols-outlined">
                                    🎥
                                </span>
                            </div>
                            <div>
                                <span>Zero bazgrania po tablicy albo tablecie, <b>100% animacji</b></span>  
                            </div>
                        </div>
                        <div className="main-page__introduction__left-section__bullet-points__bullet-point">
                            <div>
                                <span className="material-symbols-outlined">
                                    🥇
                                </span>
                            </div>
                            <div>
                                <span>Pierwsza tak rozbudowana platforma w Polsce</span>  
                            </div>
                        </div>

                    </div>
                    <div className="main-page__introduction__left-section__buttons">
                        {
                            authContext.loggedIn ? (
                                <div 
                                    className="main-page__introduction__left-section__buttons__button main-page__introduction__left-section__buttons__button--left yellow-button"
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        navigate('/userPanel')
                                    }}
                                >
                                    <span>
                                        {t("landing.goToPlatform")}
                                    </span>
                                </div>
                            ) : (
                                <Link
                                    to="/shop"
                                    title="Sklep"
                                    className="main-page__introduction__left-section__buttons__button main-page__introduction__left-section__buttons__button--left yellow-button"
                                    onClick={() => window.scrollTo(0, 0)}
                                >
                                    <span>Znajdź swój kurs</span>
                                </Link>
                            )           
                        }

                        <Link
                            to="/aboutUs"
                            title="Poznaj nasz zespół"
                            className="main-page__introduction__left-section__buttons__button main-page__introduction__left-section__buttons__button--right"
                            onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth'
                                });
                            }}
                        >
                            <span>
                                Poznaj nasz zespół
                            </span>
                            <Play/>
                        </Link>
                        

                    </div>
                </div>
                <div  className="main-page__introduction__right-section">
                    <div>

                    </div>
                </div>
            </div>



            <MediaQuery maxWidth={950}>
                <ConsultationsPanel/>
                <CoursePackage/>
                <CourseSellSection/>
            </MediaQuery>

            {/* <TeamSection/> */}

            
            {/* <Carousel/> */}
            {/* <FinalTestPreview/> */}

            {/* <Opinions/> */}
            {/* <Features/> */}
            {/* <ConsultationsPanel/> */}
            {/* <OfferBoxes/> */}
            {/* <VideoPreview/> */}

            <div ref={ref as React.Ref<HTMLDivElement>}/>
            <NewFeatureSection/>

            <MediaQuery minWidth={951}>
                <CoursePackage/>
                <Guarantee authContext={authContext} component="main page"/>
                <CourseSellSection/>
            </MediaQuery>

            <MediaQuery maxWidth={950}>
                <FinalTestPreview/>
            </MediaQuery>


            {/* <NewOpinions/> */}

            <DesktopOpinions/>

            <MediaQuery maxWidth={950}>
                <DiscordInvitation/>
            </MediaQuery>


            <div className="main-page__go-to-offers">
                <div className="main-page__go-to-offers__wrapper">
                    <div className="main-page__go-to-offers__wrapper__headline"> 
                        <span>
                            {t("landing.andNowTheBest")}
                        </span>
                    </div>
                    <div className="main-page__go-to-offers__wrapper__main-text">
                        <span>
                            {t("landing.hoursOfRecordings")}
                            <b>&nbsp;{t("landing.inPriceOfOnly3Hours")}</b>.
                        </span>
                    </div>
                    <div 
                        className="main-page__go-to-offers__wrapper__button yellow-button"
                        onClick={() => {
                            window.scrollTo(0, 0);
                            navigate("/shop")
                        }}
                    >
                        <span>
                            {t("landing.findYourFirstCourse")}
                        </span>
                        <ArrowGraphic/>
                    </div>
                </div>
            </div>


            <MessengerPanel/>

            <Cookie/>            

            <BlogInfomertial/>
            <LandingFooter/>

        </div>
    );
}