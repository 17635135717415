import { useNavigate } from "react-router-dom";

import "./CoursePanel.scss";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

interface CoursePanelProps {
    courseId: string;
    imgSrc: string;
    name: string;
    nameWithYearUpdated: string;
    description: string;
    numberOfHours: number;
    numberOfLessons: number;
    percentFinished: number;
    activeToDate: string;
}

export const CoursePanel = (props: CoursePanelProps) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();

    const handleGoToCourseClick = () => {
        navigate(`/course/${props.courseId}/main`);

        window.scrollTo({
            top: 0, 
            behavior: 'smooth'
        });
    }

    const getHeading = (name: string) => {
        const result: JSX.Element[] = [];
        const splitted = name.split(' ');

        const half = Math.ceil(splitted.length / 2);
        const firstHalfString = splitted.slice(0, half).join(' ');
        const secondHalfString = splitted.slice(half).join(' ');

        const firstHalfComponent = 
            <span key={0} className={prepName(true, firstHalfString)}>
                {firstHalfString}
            </span>

        const secondHalfComponent = 
            <span key={1} className={prepName(false, secondHalfString)}>
                {secondHalfString}
            </span>

        result.push(firstHalfComponent);
        result.push(secondHalfComponent);


        return result;
    }

    const prepName = (first: boolean, name: string) => {
        const baseName = "course-panel__main-name__text__element";
        let finalName = baseName + " " + baseName;
        finalName += (first ? "--first" : "--second") + " " + baseName;

        
        let fontSize = 27, potentialWidth = 1;
        const error = 5.0, expectedWidth = 140;

        if(calculatePotentialWidth(name, '24') <= 140) {
            fontSize = 24;
        } else {
            do {
                fontSize--;
                potentialWidth = calculatePotentialWidth(name, fontSize.toString());
            } while ( (Math.abs(expectedWidth - potentialWidth) > error) && (fontSize > 18) );
        }
        
        return finalName += `--${fontSize}`;
    }

    const calculatePotentialWidth = (name: string, size: string) => {
        let canvas = document.createElement("canvas");
        let context = canvas.getContext("2d");

        let font = `600 ${size}px Arimo`;
        if(context === null) {
            return -1;
        } else {
            context.font = font;
            let metrics = context.measureText(name);
        
            return metrics.width;
        }
    }

    const percentage = useMemo(() => Math.round(props.percentFinished * 100), [props.percentFinished]);

    return (
        <div className={`course-panel`}>
            <div className="course-panel__main-name">
                <div className="course-panel__main-name__img">
                    <img
                    src={props.imgSrc}
                    alt="kurs"
                    />
                </div>
                <div className="course-panel__main-name__text">
                    {getHeading(props.name)}
                </div>
            </div>
                <div className="course-panel__name">
                    <span>{props.nameWithYearUpdated}</span>
                </div>
                <div className="course-panel__description">
                <span>
                    {props.description}
                </span>
            </div>
            <div className="course-panel__params">
                <div className="course-panel__params__param">
                    <span className="material-symbols-outlined">schedule</span>
                    <span>+ {props.numberOfHours}h {t("kurs.preview.recordings")}</span>
                </div>
                <div className="course-panel__params__param">
                    <span className="material-symbols-outlined">play_circle</span>
                    <span>+ {props.numberOfLessons} {t("kurs.preview.lessons")}</span>
                </div>
            </div>
            <div className="course-panel__content-wrapper">
                <div className="course-panel__content-wrapper__score">
                    <span className="course-panel__content-wrapper__score__text">
                    {t("kurs.preview.completed")}:
                    </span>
                    <span className="course-panel__content-wrapper__score__perc">
                    {percentage}%
                    </span>
                </div>
                <div className="course-panel__content-wrapper__button" data-testid='go-to-course-button'
                    onClick={handleGoToCourseClick}>
                    <span>{t("kurs.preview.goToCourse")}</span>
                </div>
            </div>
            <div className="course-panel__valid-time">
                <span>{t("kurs.preview.courseValidUntil")} {props.activeToDate}</span>
            </div>
        </div>
    );
}