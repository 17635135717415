
import { Navigate, useNavigate } from "react-router-dom";
import "../mainPage.scss";

import "./blogInfomertial.scss";

export const BlogInfomertial = () => {
   

    return (
        <section className="main-page__blogInfomertial">
            <div className="main-page__blogInfomertial__text">
                <h2>Sprawdź naszego bloga 👋</h2>
                <div className="main-page__blogInfomertial__text__spacer"></div>
                <span>Oprócz kursów prowadzimy w pełni <b>darmowego bloga</b> z wybranymi artykułami i zadaniami na studia.</span>
                <div className="main-page__blogInfomertial__text__button-wrapper">
                    <a href="https://blog.nasesje.com"
                        title="Blog Nasesje"
                    >
                        <button>
                            <span>Przejdź do bloga </span>
                        </button>
                    </a>
                </div>

            </div>
            <img
                className="infty-right" 
                src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/landing-page/infty.png" 
                alt="mathematical symbols">    
            </img>


        </section>
    )
}