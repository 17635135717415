import { useRef, useEffect, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Authors, AuthorsData } from "../CourseSectionsWrapper/DisplayedCourseDescription/Authors/Authors";
import { BuyNowButton } from "../../BuyNowButton/BuyNowButton";
import MediaQuery from "react-responsive";

interface DisplayedDescriptionWrapperProps {
    setTableOfContentsRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
    setAboutAuthorsRef: Dispatch<SetStateAction<HTMLDivElement | null>>;
    fromLanding?: boolean;
    courseName: string;
    children: React.ReactNode;
    isAllCoursesBundle: boolean;
    handleBuyNowClick: () => void;
}

export const DisplayedDescriptionWrapper = (props: DisplayedDescriptionWrapperProps) => {
    const aboutAuthorsRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    useEffect(() => {
        props.setAboutAuthorsRef(aboutAuthorsRef.current);
    }, [aboutAuthorsRef])


    return (
        <div className={`course-preview__main-section__left-panel__text-section ${(props.fromLanding) && "course-preview__main-section__left-panel__text-section--from-landing"}`}>

            <div className="course-preview__main-section__left-panel__text-section__learning-method">
                
                {
                    props.fromLanding &&              
                    <div className="course-preview__main-section__left-panel__text-section__learning-method__from-landing-separator course-preview__main-section__left-panel__text-section__learning-method__from-landing-separator--top">

                    </div> 
                }
                <h1>
                    Dokładne informacje
                </h1>

                {props.children}

                <div 
                    ref={aboutAuthorsRef}
                    className="course-preview__main-section__left-panel__text-section__about-author">
                    <h2>
                        O autorach:
                    </h2>
                   {
                        AuthorsData.map((el, index) => {
                            if(el.myCourses?.some(el => el  === props.courseName) || props.isAllCoursesBundle)
                                return <Authors
                                    key={index}
                                    imgSrc={el.imgSrc}
                                    name={el.name}
                                    description={el.description}
                                    bulletPoints={el.bulletPoints}
                                />
                            else return <></>
                        })
                   }
                </div>     

                <MediaQuery maxWidth={950}>
                    <div className="course-preview__main-section__repeated-cta">
                        <div className="course-preview__main-section__repeated-cta__separator"></div>
                        <div className="course-preview__main-section__repeated-cta__text">
                            <div>
                                <div className="course-preview__main-section__repeated-cta__text__first-line">
                                    Zaufały nam już
                                </div>
                                <div className="course-preview__main-section__repeated-cta__text__second-line">
                                    <span>setki</span> studentów!
                                </div>
                            </div>
                            {/* <div className="course-preview__main-section__repeated-cta__text__icon">
                                👨‍🎓👩‍🎓
                            </div> */}
                            <div className="course-preview__main-section__repeated-cta__text__icon">
                                🎓
                            </div>
                        </div>
                        <div className="course-preview__main-section__repeated-cta__text__bottom-text">
                            Dołącz do zadowolonych studentów, którym pomogliśmy zdać kolokwia i egzaminy!
                        </div>
                        <div className="course-preview__main-section__right-panel__buttons">
                            <BuyNowButton 
                                className={"not-selectable course-preview__main-section__right-panel__buttons__button course-preview__main-section__right-panel__buttons__button--left course-preview__main-section__right-panel__buttons__button--active"}
                                onClick={props.handleBuyNowClick}
                            />
                            <div className="course-preview__main-section__repeated-cta__guarantee">
                                <span>7-dniowa gwarancja na każdy kurs</span>
                            </div>
                        </div>
                    </div>
                </MediaQuery>           

                {
                    props.fromLanding &&              
                    <div 
                        className="course-preview__main-section__left-panel__text-section__learning-method__from-landing-separator course-preview__main-section__left-panel__text-section__learning-method__from-landing-separator--bottom">

                    </div> 
                }
            </div>
        </div>
    )
}