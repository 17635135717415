

import "./desktopOpinions.scss";


import instagramLogo from '../TeamSection/socialIcons/Instagram_logo.png';

import opinionPhoto from './photoOpinion_result.webp';
import circeledArrow from './circeledArrow.png';

import { ArrowGraphic } from "../graphics/ArrowGraphic";
import { TikTok } from "../TeamSection/socialIcons/TikTok";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { PersonIcon } from "../../Icons/PersonIcon";
import { FavoriteIcon } from "../../Icons/FavoriteIcon";
import { QuoteIconFilled } from "../../Icons/QuoteIconFilled400";
import { DiscordLogo } from "../DiscordInvitation/DiscordLogo";




export const DesktopOpinions = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const opinionData = [
        { name: "Antek", source: "granice ciągów", text: "W skrócie to nie rozumiałem jak liczy się całki, a dzięki tym materiałom wiem o co chodzi. Coś czego nie mogłem zroumieć na ćwiczeniach tutaj w końcu jest wytłumaczone prosto.", img: "" },
        { name: "Tomek", source: "liczby zespolone",  text: "Dzięki wam zdałem pierwszego kolosa, wystarczyły zadania z nagrań, nawet bez tych dodatkowych do lekcji.", img: "" },
        { name: "Martyna", source: "macierze i wektory", text: "Fajnie napisane notatki do lekcji, dużo lepsze od slajdów z wykładu.", img: "" }
    ]


    return (
        <div className="main-page__desktop-opinions">
            
            <div className="main-page__desktop-opinions__headline">
                <h3 className="desktop">Poznaj zespół nasesje i naszych <b>zadowolonych</b> studentów!</h3>

                <h3 className="mobile">Co mówią o nas studenci?</h3>
            </div>

            <div className="main-page__desktop-opinions__content">

                <div className="main-page__desktop-opinions__content__left">
                

                    <div className="main-page__desktop-opinions__content__left__socials">
                        <div className="not-selectable main-page__desktop-opinions__content__left__socials__tik-tok">
                            <a title="tiktok" href="https://www.tiktok.com/@nasesje.com"><TikTok/></a>
                        </div>

                        <div className="not-selectable main-page__desktop-opinions__content__left__socials__instagram">
                            <a title="instagram" href="https://www.instagram.com/nasesje/"><img src={instagramLogo} alt="" /></a>
                        </div>

                        <div className="main-page__desktop-opinions__content__left__socials__data">

                            <div className="main-page__desktop-opinions__content__left__socials__data__headline">
                                <span>Co mówią o nas liczby:</span>
                            </div>

                            <div className="main-page__desktop-opinions__content__left__socials__data__stats">
                                <div className="main-page__desktop-opinions__content__left__socials__data__stats__batch">
                                    <b>+ </b>
                                    <span>17K </span>
                                    <PersonIcon/>
                                </div>
                                <div className="main-page__desktop-opinions__content__left__socials__data__stats__batch">
                                    <b>+ </b>
                                    <span>415K </span>
                                    <FavoriteIcon/>
                                </div>
                            </div>

                        </div>
                    </div>  


                    <div className="main-page__desktop-opinions__content__left__discord">
                        <Link
                            to="https://discord.gg/vdnuPq8E65"
                            target="_blank"
                            title="Dołącz do społeczności na discordzie"
                            className="main-page__desktop-opinions__content__left__discord__logo">
                            <DiscordLogo/>
                        </Link>
                        <img src={circeledArrow} alt="circeled arrow" />
                        <span>Dołącz do <b>społeczności</b> na discordzie</span>
                    </div>
                    
                    <img 
                        className="main-page__desktop-opinions__content__left__image"
                        src="https://na-sesje-public.s3.eu-central-1.amazonaws.com/grupowe_bez_tla-min_3.webp" 
                        alt="nasesje.com team">    
                    </img>


  

                    <div className="main-page__desktop-opinions__content__left__about-us">
                        <span>Razem z całym zespołem dbamy o najlepszą jakość materiałów i wsparcie w trakcie kursu. </span>
                        <Link 
                            title="Poznaj nasz zespół"
                            to="/aboutUs"
                            onClick={(e) => {
                                e.preventDefault();
                                window.scrollTo(0, 0);
                                navigate("/aboutUs");
                            }}
                        >
                            Poznaj nasz zespół
                        </Link>
                    </div>
                </div>

                <div className="main-page__desktop-opinions__content__right">
                    <div className="main-page__desktop-opinions__content__right__column-1">
                       <div  className="opinion">
                            <span className="opinion__text">
                                W skrócie to nie rozumiałem jak liczy się całki, a <b>dzięki tym materiałom wiem o co chodzi</b>. Coś czego nie mogłem zrozumieć na ćwiczeniach tutaj w końcu jest wytłumaczone prosto 😌
                            </span>
                            <br></br>
                            <br></br>
                            <span className="opinion__name">@Ania</span>
                            <span className="opinion__course"> - macierze i wektory </span>
                       </div>

                       <div  className="opinion">
                            <span>
                            Hej, mam wasz kurs z liczb zespolonych 😊 ogólnie wszystko super i wszystko jest fajnie wytłumaczone, bo <b>była to dla mnie czarna magia, a teraz już nie jest</b>! <br></br>Jak mnie ktoś pyta, to chętnie was polecam!
                            </span>
                            <br></br>
                            <br></br>
                            <span className="opinion__name">@Marcin</span>
                            <span className="opinion__course"> - liczby zespolone</span>
                       </div>


                    </div>
                    <div className="main-page__desktop-opinions__content__right__column-2">
                        <div  className="opinion">
                            <span>
                            Dzięki wam <b>zdałem pierwszego kolosa</b>, wystarczyły zadania z nagrań, nawet bez tych dodatkowych do lekcji.
                            </span>
                            <br></br>
                            <br></br>
                            <span className="opinion__name">@Tomek</span>
                            <span className="opinion__course"> - liczby zespolone</span>
                       </div>

                       <div className="opinion opinion--paddingless">
                            <img src={opinionPhoto} alt="user using platform" />
                            <span>Egzamin <b>zdany w pierwszym terminie</b> 🙏</span>

                            <span className="opinion__name">@Agnieszka - granice ciągów</span>
                           
                       </div>
                    </div>
                </div>


            </div>
        </div>
    )
}