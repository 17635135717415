
import { Dispatch, SetStateAction, useMemo, useState } from "react";
// import { DisplayedCourseDescription } from "./DisplayedCourseDescription/DisplayedCourseDescription";
import { Block } from "../../../../../../shared/block";
import { useTranslation } from "react-i18next";
import { DisplayedOption } from "../DisplayedOption/DisplayedOption";
import { SectionWrapperProps } from "../sectionWrapperProps";
import { DisplayedCourseDescription } from "../CourseSectionsWrapper/DisplayedCourseDescription/DisplayedCourseDescription";
import { DisplayedBundleDescription } from "./DisplayedBundleDescription/DisplayedBundleDescription";
import { PanelSelectorWrapper } from "../PanelSelectorWrapper/PanelSelectorWrapper";
import { BuyAllCoursesData } from "shared/coursesPreviewDto";
import { getPriceAfter } from "../../../../utilities/pricing/discount";

export interface BundleContent {
    courseName: string;
    courseImage: string;
    chapters?: {
        chapterName: string;
        lessons: string[];
    }[];
}

interface BundleSectionsWrapperProps extends SectionWrapperProps {
    buyAllCoursesData: BuyAllCoursesData;
}

export const BundleSectionsWrapper = (props: BundleSectionsWrapperProps) => {
    const [tableOfContentsRef, setTableOfContentsRef] = useState<HTMLDivElement | null>(null);
    const [aboutAuthorsRef, setAboutAuthorsRef] = useState<HTMLDivElement | null>(null);

    const getBundleContent = useMemo<BundleContent[]>(() => props.buyAllCoursesData.courses.map(el => ({
            courseName: el.courseName,
            courseImage: el.courseImage,
            chapters: el.chapters
        })), [props.buyAllCoursesData]);

    return (

        <>
            {
                (!props.fromLanding) && 
                <PanelSelectorWrapper>
                    <DisplayedOption 
                        myIndex={0}
                        text={"Opis Paczki"}
                        tableOfContentsRef={tableOfContentsRef}
                        isActive={true}
                    />
                    <DisplayedOption 
                        myIndex={1}
                        text={"Spis Treści"}
                        tableOfContentsRef={tableOfContentsRef}
                        isActive={false}

                    />
                    <DisplayedOption 
                        myIndex={2}
                        text={"O Autorach"}
                        tableOfContentsRef={aboutAuthorsRef}
                        isActive={false}

                    />
                </PanelSelectorWrapper>
            }
            <DisplayedBundleDescription 
                description={props.description}
                bundleContent={getBundleContent}
                setTableOfContentsRef={setTableOfContentsRef}
                setAboutAuthorsRef={setAboutAuthorsRef}
                fromLanding={props.fromLanding}
                courseName={props.courseName}
                handleBuyNowClick={props.handleBuyNowClick}
                bundlePriceAfterDiscount={getPriceAfter(props.buyAllCoursesData.coursePack.discount, props.buyAllCoursesData.coursePack.price)}
            />
        </>
    )
}