
import { Dispatch, SetStateAction, useState } from "react";
import { DisplayedCourseDescription } from "./DisplayedCourseDescription/DisplayedCourseDescription";
import { Block } from "../../../../../../shared/block";
import { useTranslation } from "react-i18next";
import { DisplayedOption } from "../DisplayedOption/DisplayedOption";
import { SectionWrapperProps } from "../sectionWrapperProps";
import { PanelSelectorWrapper } from "../PanelSelectorWrapper/PanelSelectorWrapper";

interface CourseContentWrapperProps extends SectionWrapperProps {
    publiclyAvailableChapter: number;
    publiclyAvailableLessons: number[];
}

export const CourseSectionsWrapper = (props: CourseContentWrapperProps) => {
    const [tableOfContentsRef, setTableOfContentsRef] = useState<HTMLDivElement | null>(null);
    const [aboutAuthorsRef, setAboutAuthorsRef] = useState<HTMLDivElement | null>(null);

    return (

        <>
            {
                (!props.fromLanding) && 
                <PanelSelectorWrapper>
                    <DisplayedOption 
                        myIndex={0}
                        text={"Opis Kursu"}
                        tableOfContentsRef={tableOfContentsRef}
                        isActive={true}
                    />
                    <DisplayedOption 
                        myIndex={1}
                        text={"Spis Treści"}
                        tableOfContentsRef={tableOfContentsRef}
                        isActive={false}

                    />
                    <DisplayedOption 
                        myIndex={2}
                        text={"O Autorach"}
                        tableOfContentsRef={aboutAuthorsRef}
                        isActive={false}

                    />
                </PanelSelectorWrapper>
            }
            <DisplayedCourseDescription
                    description={props.description}
                    courseContent={props.courseContent}
                    publiclyAvailableChapter={props.publiclyAvailableChapter} 
                    publiclyAvailableLessons={props.publiclyAvailableLessons}
                    setTableOfContentsRef={setTableOfContentsRef}
                    setAboutAuthorsRef={setAboutAuthorsRef}
                    fromLanding={props.fromLanding}
                    courseName={props.courseName}
                    handleBuyNowClick={props.handleBuyNowClick}
                />
        </>
    )
}