import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";
import { SellingCoursePanel } from "../../SellingCoursePanel/SellingCoursePanel";
import { GoToShopPanel } from "./GoToShopPanel/GoToShopPanel";

import './courseSellSection.scss';

import { useTranslation } from "react-i18next";
import { UserCoursesDto }  from "../../../../../shared/userCoursesDto";
import { CoursePreviewMainPage } from "../../../../../shared/coursesPreviewDto";
import { get } from "../../../utilities/rest/apiClient";
import { AuthContext } from "../../../components/auth/authContext/AuthContextProvider";
import { GlobalDataContext } from "../../../components/global/globalDataContext/GlobalDataContextProvider";


interface MobilePageButtonProps {
    displayedNumber: number, 
    myIndex: number,
    activeIndex: number,
    setActiveIndex: Dispatch<SetStateAction<number>>
    handleScrollClick: () => void;
}

const MobilePageButton = (props: MobilePageButtonProps) => {
    const  { myIndex, activeIndex } = props;

    const handleClass = () => {
        const base = "main-page__offers__mobile-menu__panels__button";
        return `not-selectable ${base} ${base}--${(myIndex === activeIndex) ? "active" : "inactive"}`;
    }

    

    return (
        <div 
            className={handleClass()}
            onClick={() => { 
                if(myIndex !== activeIndex) { 
                    props.setActiveIndex(myIndex);

                    props.handleScrollClick();
                }
            }}
        >
            <span>{props.displayedNumber}</span>
        </div>
    );    
}

export const CourseSellSection = () => {
    const { t, i18n } = useTranslation();

    const [isLoading, setIsLoading] = useState(true);
    const [coursesData, setCoursesData] = useState<CoursePreviewMainPage[]>([]);
    const [coursePages, setCoursePages] = useState(0);
    const [activeIndex, setActiveIndex] = useState(0);
    const [possesedCourses, setPossesedCourses] = useState<string[]>([]);

    const authCtx = useContext(AuthContext);
    const globalDataContext = useContext(GlobalDataContext);

    const ref = useRef<HTMLElement | null>(null);

    const handleScrollToClick = () => {
        if(ref.current !== null) {
            ref.current.scrollIntoView({ 
                behavior: 'smooth',
            });
        }
    };
    

    const handleCourseOffersDisplay = (fullRange: boolean, lowId?: number, highId?: number) => {
        let lowIndex = 0, highIndex = 0;

        if(fullRange) {
            highIndex = 2;
        }

        else if(lowId !== undefined && highId !== undefined) {
            if(lowId < 0) 
                return "";
            else 
                lowIndex = lowId;

            if( highId > coursesData.length)
                highIndex = coursesData.length;
            else
                highIndex = highId;
        }

        const subarray = coursesData.slice(lowIndex, highIndex+1);

        const returnedPanels =  subarray.map((el, index) => 
            <SellingCoursePanel
                key={index}
                first={index === 0}
                last={index === (coursesData.length-1)}
                courseId={el.id}
                imgSrc={el.imgSrc}
                name={el.name}
                nameWithYearUpdated={`${el.name} - ${el.lastUpdate}`}
                description={el.description}
                numberOfHours={el.numberOfHours}
                numberOfLessons={el.numberOfLessons}
                price={el.price}
                discount={el.discount}
                lastUpdate={el.lastUpdate.toString()}
                possesion={ possesedCourses.includes(el.id) }
                coursePubliclyAvailable={el.coursePubliclyAvailable}
                isAllCoursesBundle={el.isAllCoursesBundle}
            />
        );

        const dummyKey=111;
        if(fullRange || (subarray.length < 3) ) returnedPanels.push(<GoToShopPanel key={dummyKey}/>);

        return returnedPanels;
    }


    useEffect(() => {
    setIsLoading(true);
        if(authCtx.isLoading) return;

        (async ()=> {
            try {
              if(authCtx.loggedIn) {
                const userCourses = await get<UserCoursesDto>(`users/getUserCourses`);
                
                const activeCourseIds = userCourses.activeCourses.map(course => course.referencedCourse);
                setPossesedCourses(activeCourseIds);

              }
              
              const data = await get<CoursePreviewMainPage[]>(`reactRoutes/getCoursesPreviewForMainPage/${i18n.language}/7`);
              
              globalDataContext.setAllCoursesBundleId(data.find(el => el.isAllCoursesBundle)?.id ?? ""); //TODO course
              setCoursesData(data)
              setCoursePages(Math.max(3, Math.ceil(data.length / 3)));

              setIsLoading(false);
            } catch(error) {
              console.log(error);
              //TODO other errors
            }
          })()
    },  [authCtx.isLoading]);

    if(isLoading) return <></>
    return (
        <div className="main-page__offers">
            <div className="main-page__offers__headline" ref={ref as React.Ref<HTMLDivElement>}>
                <span className="main-page__offers__headline__main main-page__offers__headline__main--desktop">
                    {t("landing.avaiable")}&nbsp;
                    <b>{t("landing.courses")} </b> 📚
                </span>
                <span className="main-page__offers__headline__main main-page__offers__headline__main--mobile">
                    Znajdź <b>kurs</b> 📚
                </span>
                <br/>
                <span className="main-page__offers__headline__description main-page__offers__headline__description--desktop">
                    {t("landing.everyCourseContains")}
                </span>

                <span className="main-page__offers__headline__description main-page__offers__headline__description--mobile">
                    nagrania, interaktywne testy, notatki, zadania otwarte
                </span>
            </div>



            {/* <div className="main-page__offers__artificial-spacer main-page__offers__artificial-spacer--top main-page__offers__artificial-spacer--mobile"/> */}
            <div className="main-page__offers__scrollable main-page__offers__scrollable--desktop">
                {
                    handleCourseOffersDisplay(true)
                }
            </div>

            <div className="main-page__offers__scrollable main-page__offers__scrollable--mobile">
                {
                    handleCourseOffersDisplay(false, activeIndex*3, activeIndex*3+2)
                }
            </div>


                
            <div className="main-page__offers__artificial-spacer main-page__offers__artificial-spacer--bottom"/>

            <div className="main-page__offers__mobile-menu">

                <div className="main-page__offers__mobile-menu__panels">
                    {
                        Array.from({ length:  coursePages}, (_, index) => index + 1)
                        .map((el, index) => 
                            <MobilePageButton 
                                key={index}
                                displayedNumber={el}
                                myIndex={index}
                                activeIndex={activeIndex}
                                setActiveIndex={setActiveIndex}
                                handleScrollClick={handleScrollToClick}
                            />)
                    }
                </div>
            </div>


        </div>
    )
}